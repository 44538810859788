import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../api.service';
import {ToastrService} from "ngx-toastr";
import {NgIf} from "@angular/common";
import {QuillEditorComponent} from "ngx-quill";

@Component({
    selector: 'app-informed-consent-form',
    standalone: true,
    imports: [NgbTooltipModule, FormsModule, RouterLink, ReactiveFormsModule, NgIf, QuillEditorComponent],
    providers: [ApiService],
    templateUrl: './informed-consent-form.component.html',
})
export class InformedConsentFormComponent implements OnInit {
    @Input() studyId: number

    formGroup: FormGroup;
    study: any;

    errorMessage = false;
    icfParticipant!: any;
    icfParticipantAdolescent!: any
    icfCarer!: any;
    icfStudyManager!: any;

    participantList: string[] = []
    participantAdolescentList: string[] = []
    carerList: any[] = []
    studyManagerList: any[] = []

    constructor(private formBuilder: FormBuilder, private api: ApiService, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            icfParticipant: ["", Validators.required],
            icfAdditionalParticipant: [""],
            icfParticipantAdolescent: ["", Validators.required],
            icfAdditionalParticipantAdolescent: [""],
            icfCarer: ["", Validators.required],
            icfAdditionalCarer: [""],
            icfStudyManager: ["", Validators.required],
            icfAdditionalStudyManager: [""],
        });
        if (this.studyId) {
            this.api.getStudyById(this.studyId).subscribe(res => {
                this.study = res.data
                this.formGroup.patchValue({
                    icfParticipant: res.data.icfParticipant,
                    icfParticipantAdolescent: res.data.icfParticipantAdolescent,
                    icfCarer: res.data.icfCarer,
                    icfStudyManager: res.data.icfStudyManager,
                })

                res.data.icfClauses.forEach(item => {
                    if (item.type == "Participant") {
                        this.participantList.push(item.description)
                    }
                })
                res.data.icfClauses.forEach(item => {
                    if (item.type == "Participant Adolescent") {
                        this.participantAdolescentList.push(item.description)
                    }
                })
                res.data.icfClauses.forEach(item => {
                    if (item.type == "Carer") {
                        this.carerList.push(item.description)
                    }
                })
                res.data.icfClauses.forEach(item => {
                    if (item.type == "Study Manager") {
                        this.studyManagerList.push(item.description)
                    }
                })
            })
        }
    }

    addParticipant() {
        const value = this.formGroup.get("icfAdditionalParticipant").value
        if (value != null) {
            this.participantList.push(value)
            this.formGroup.get("icfAdditionalParticipant").setValue(null)
        }
    }

    removeParticipant(index: any) {
        this.participantList.splice(index, 1);
    }

    addParticipantAdolescent() {
        const value = this.formGroup.get("icfAdditionalParticipantAdolescent").value
        if (value != null) {
            this.participantAdolescentList.push(value)
            this.formGroup.get("icfAdditionalParticipantAdolescent").setValue(null)
        }
    }

    removeParticipantAdolescent(index: any) {
        this.participantAdolescentList.splice(index, 1);
    }

    addCarer() {
        const value = this.formGroup.get("icfAdditionalCarer").value
        if (value != null) {
            this.carerList.push(value)
            this.formGroup.get("icfAdditionalCarer").setValue(null)
        }
    }

    removeCarer(index: any) {
        this.carerList.splice(index, 1);
    }

    addStudyManager() {
        const value = this.formGroup.get("icfAdditionalStudyManager").value
        if (value != null) {
            this.studyManagerList.push(value)
            this.formGroup.get("icfAdditionalStudyManager").setValue(null)
        }
    }

    removeStudyManager(index: any) {
        this.studyManagerList.splice(index, 1);
    }

    submitForm() {
        if (this.formGroup.valid) {
            const payload = {
                startDate: null,
                plannedEndDate: null,
                durationInWeeksPerParticipant: this.study.durationInWeekPerParticipant,
                studyTeamMembers: this.study.studyTeamMembers.map(item => item.user.id),
                icfParticipant: this.formGroup.get("icfParticipant").value,
                icfParticipantAdolescent: this.formGroup.get("icfParticipantAdolescent").value,
                icfCarer: this.formGroup.get("icfCarer").value,
                icfStudyManager: this.formGroup.get("icfStudyManager").value,
                icfClauses: [
                    ...this.participantList.map(item => ({type: "Participant", description: item})),
                    ...this.participantAdolescentList.map(item => ({type: "Participant Adolescent", description: item})),
                    ...this.carerList.map(item => ({type: "Carer", description: item})),
                    ...this.studyManagerList.map(item => ({type: "Study Manager", description: item})),
                ],
                studyMeasurements: [],
                studyVideoDiaryTopics: null,
                studySurveys: [],
                studyWearables: [],
            }
            this.api.putStudy(payload, this.studyId).subscribe(res => {
                if (res && res.data) {
                    this.toastr.success(res?.message, ' ');
                } else {
                    this.toastr.error(res.error, ' ');
                }
            });
        } else {
            this.formGroup.markAllAsTouched()
            this.toastr.error('Please provide a value for all the required fields');
        }
    }
}
