<h5 class="mt-2 mb-0">Informed Consent Form for participant</h5>
<hr class="mt-2 mb-4" />

<p class="mb-2">Not signed yet</p>
<img class="rounded" src="https://placehold.co/70" alt="" />
<p class="mt-2"><a class="text-primary">Download unsigned ICF</a></p>

<h5 class="mt-4 pt-3 mb-0">
  Informed Consent Form for participant
  <span class="fw-normal"
    >(Only requested when participant is younger than 16 years old)</span
  >
</h5>
<hr class="mt-2 mb-4" />

<p class="mb-2">Not signed yet</p>
<img class="rounded" src="https://placehold.co/70" alt="" />
<p class="mt-2"><a class="text-primary">Download unsigned ICF</a></p>

<h5 class="mt-4 pt-3 mb-0">Study Manager Control Check</h5>
<hr class="mt-2 mb-4" />

<p class="mb-2">Not signed yet</p>
<img class="rounded" src="https://placehold.co/70" alt="" />
<p class="mt-3"><a class="text-primary">Download unsigned ICF</a></p>
