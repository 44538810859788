<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Participant</a></li>
        <li class="breadcrumb-item active" aria-current="page">
            Add New Participant
        </li>
    </ol>
</nav>

<div class="card">
    <h4 class="card-header">Add New Participant</h4>
    <div class="card-body px-2 py-4">
        <spinner *ngIf="loading"></spinner>
        <mat-stepper [linear]="true" #stepper>

            <mat-step editable="false" completed="false" label="Study Information">
                <study-information (participantData)="finishStudyParticipant($event)"></study-information>
            </mat-step>

            <mat-step editable="false" completed="false" label="Screening form">
                <screening-form (formResponse)="finishScreeningForm()"></screening-form>
            </mat-step>

            <mat-step editable="false" completed="false" label="Baseline Data">
                <personal-info (result)="finishPersonalInfo()"></personal-info>
            </mat-step>

            <mat-step editable="false" completed="false" label="Demographics">
                <demographics (formResponse)="finishDemographicForm()" (prevClicked)="prev()"></demographics>
            </mat-step>

            <mat-step editable="false" completed="false" label="Confirmation">
                <confirmation (prevClicked)="prev()"></confirmation>
            </mat-step>
        </mat-stepper>
    </div>
</div>
