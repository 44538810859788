<ng-container *ngIf="!isLayoutDisabled">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
</ng-container>

<main [class.main-content]="!isLayoutDisabled">
  <div [class.container-fluid]="!isLayoutDisabled">
    <router-outlet />
  </div>
</main>

<div style="position:absolute; bottom:0; right:0; padding-bottom:30px; padding-right:30px" *ngIf="isUserLogged && idleState == 'IDLE'">
    <div class="card text-bg-warning" style="width: 18rem;">
        <div class="card-body">
            <h5 class="card-title">Session Idle</h5>
            <p class="card-text">
                The session will be closed after the inactivity time.
            </p>
            <p><b>{{countdown}} seconds left.</b></p>
            <ngb-progressbar class="mb-3" type="dark" [value]="percentage" />
        </div>
    </div>
</div>


<ng-container *ngIf="!isLayoutDisabled">
    <app-footer></app-footer>
</ng-container>
