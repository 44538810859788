<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/participant-list">Participants</a></li>
    <li class="breadcrumb-item active" aria-current="page">List</li>
  </ol>
</nav>

<div class="card">
  <h4 class="card-header">Participants List</h4>
  <div class="card-body">
      <participants-table></participants-table>
  </div>
</div>
