import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {ApiService} from "../../../../api.service";
import {Site, Study} from "../../../../api/api.interfaces";
import {ToastrService} from "ngx-toastr";
import {NgIf} from "@angular/common";
import {SpinnerComponent} from "../../../spinner/spinner.component";

@Component({
    selector: 'study-information',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        SpinnerComponent
    ],
    templateUrl: './study-information.component.html',
})
export class StudyInformationComponent implements OnInit {
    @Output() participantData = new EventEmitter<{participantId: number, studyId: number, siteId: number}>();

    formGroup = this.formBuilder.group({
        study: ['', Validators.required],
        studySite: ['', Validators.required],
    });

    loading: boolean = true

    studyList: Study[]
    currentSiteList: Site[]

    constructor(
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.apiService.studyListing({}).subscribe(response => {
            this.studyList = response.data
        })

        this.formGroup.get("studySite").disable()
        this.formGroup.get("study").valueChanges.subscribe(value => {
//            this.loading = true
            this.currentSiteList = []
            this.formGroup.get("studySite").setValue("")
            this.formGroup.get("studySite").disable()
            if (value != "") {
                this.querySites()
            }
        })
    }

    private querySites() {
        this.loading = true
        const selectedStudyId = parseInt(this.formGroup.get("study").value)
        this.apiService.siteListing({study:selectedStudyId, size:9999}).subscribe(response => {
            this.currentSiteList = response.data
            this.loading = false
            this.formGroup.get("studySite").enable()
        })
    }

    processForm() {
        if (this.formGroup.valid) {
            this.loading = true
            const studyId = parseInt(this.formGroup.get("study").value)
            const siteId = parseInt(this.formGroup.get("studySite").value)
            this.apiService.postParticipant(studyId, siteId).subscribe(response => {
                this.participantData.emit({
                    studyId: studyId,
                    siteId: siteId,
                    participantId: response.data.id
                });
            })
        } else {
            this.toastr.error('Please provide a value for all the required fields');
            this.formGroup.markAllAsTouched()
        }
    }
}
