<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">Data Collection Settings</h5>
</div>

<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-4">
    <h6 class="text-success mb-3">On-site measurements</h6>
    <div class="form-group mb-3">
      <select class="form-select" [(ngModel)]="selectedMeasurement">
        <option selected value="">Select measurements</option>
        @for (item of measurementsDropdownlists; track $index) {
        <option value="{{ item.id }}">{{ item?.name }}</option>
        }
      </select>
    </div>
  </div>

  <div class="col-md-4">
    <h6 class="text-success mt-4 mt-md-0 mb-3">In-app digital measurements</h6>

    @for (item of inAppTasks; track $index) {
    <div class="form-check form-switch mb-2">
      <input
        (change)="dairyChange()"
        class="form-check-input"
        type="checkbox"
        name=" {{ item.name }} - {{ item.id }}"
        [(ngModel)]="item.checkbox"
        role="switch"
        id="appTasks{{item.id}}"
      />
      <label class="form-check-label" for="appTasks{{item.id}}">{{ item?.name }}</label>
    </div>
    }
  </div>
  <div class="col-md-4">
    <h6 class="text-success mb-3">In-app scales/surveys</h6>
    @for (item of inAppSurveys; track $index) {
    <div class="form-check form-switch mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        name=" {{ item.name }} - {{ item.id }}"
        [(ngModel)]="item.checkbox"
        role="switch"
        id="appSurvey{{item.id}}"
      />
      <label class="form-check-label" for="appSurvey{{item.id}}">{{ item?.name }}</label>
    </div>
    }
  </div>
</div>
@if (videoDiaryDisabled) {
    <div class="row justify-content-end">
        <div class="col-md-8">
            <h6 class="text-success mt-5 mb-2">
                Video diary topics *
                <button class="btn" (click)="add()">
                    <span class="bi bi-plus-circle text-info"></span>
                </button>
            </h6>
            @for (item of videodiarytopicsObject; track $index) {
                <div class="row">
                    <div class="col-sm-7 col-md-5">
                        <div class="form-group mb-3">
                            <ng-select placeholder="Select topic" [dropdownPosition]="'top'" name="topicId-{{ $index }}" [(ngModel)]="item.topicId">
                                @for (item of videodiarytopicsList; track $index) {
                                    <ng-option value="{{ item.id }}-{{ item.name }}">{{item.name}}</ng-option>
                                }
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-5 col-md-3">
                        <div class="form-group mb-3 d-flex">
                            <input type="number" class="form-control" name="durationSecs-{{ $index }}" [(ngModel)]="item.durationSecs" placeholder="Duration in secs"/>
                            <button class="btn text-danger" (click)="remove($index)">
                                <span class="bi bi-x-circle"></span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}

<!-- <div class="col-md-4">
    <h6 class="text-success mt-4 mt-md-0 mb-3">In-app scales/surveys</h6>

    @for (item of inAppLists; track $index) {
      <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" name=" {{item.name}} - {{item.id}}" [(ngModel)]="item.checkbox"
          role="switch" id="weight" />
        <label class="form-check-label" for="weight">{{ item?.name }}</label>
      </div>
      }
  </div>
</div> -->
