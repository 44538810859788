<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/site-list">Sites</a></li>
    <li class="breadcrumb-item active" aria-current="page"> {{siteId ? 'Edit Site' : ' Add New Site' }}</li>
  </ol>
</nav>

<div class="card add-new-site">
  <h4 class="card-header"> {{siteId ? 'Edit Site' : ' Add New Site' }} </h4>
  <div class="card-body px-2 py-4">
    <mat-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup" label="Site Information">
        <form [formGroup]="firstFormGroup">
          <app-site-info></app-site-info>

          <div class="text-end mt-4">
            <button mat-button class="btn btn-success" (click)="next()">
              Next <i class="bi bi-arrow-right ms-1"></i>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="fourFormGroup">
        <ng-template matStepLabel>Confirmation</ng-template>
        <div class="d-flex justify-content-between align-items-center mt-2">
          <h5 class="mb-0">Study Site Information</h5>
          <a href="#" tooltipClass="custom-tooltip" ngbTooltip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy">
            <i class="bi bi-info-circle"></i>
          </a>
        </div>

        <hr class="mt-2 mb-4" />

        <div class="row mb-3">
          <div class="col-md-6">
            <p><span class="fw-medium">Site Name:</span> {{ SiteInfoComponent?.siteForm?.value?.name }} </p>
            <p><span class="fw-medium">City:</span> {{ SiteInfoComponent?.siteForm?.value?.city }} </p>
            <p><span class="fw-medium">Country:</span> {{ SiteInfoComponent?.countryName }} </p>
          </div>
          <div class="col-md-6">
            <p><span class="fw-medium">Address:</span> {{ SiteInfoComponent?.siteForm?.value?.address }}</p>
            <p><span class="fw-medium">Postal Code:</span> {{ SiteInfoComponent?.siteForm?.value?.postalCode }}</p>
          </div>
        </div>

        <h5 class="mt-5">Connect Site to Studies</h5>
        <hr class="mt-2 mb-3" />

          @if (SiteInfoComponent?.studyListInfo.length == 0) {
              <p>No studies added</p>
          }
        @for (item of SiteInfoComponent?.studyListInfo; track $index) {
          <p><span class="fw-medium">Study:</span> {{  item.name }} </p>
        }

          <h5 class="mt-5">Team Management</h5>
          <hr class="mt-2 mb-3" />

          @if (SiteInfoComponent?.memberListInfo.length == 0) {
              <p>No Site Team Members added</p>
          } @else {
              <div class="row">
                  <div class="col-6">
                      @for (item of SiteInfoComponent?.memberListInfo; track $index) {
                          <p><span class="fw-medium">Site Team Member:</span> {{item.firstName}} {{item.lastName}} </p>
                      }
                  </div>
                  <div class="col-6">
                      <p><span class="fw-medium">Principal Investigator:</span>
                          @if (SiteInfoComponent?.getPrincipalInvestigator() == null) {
                              Not defined
                          } @else {
                              {{SiteInfoComponent?.getPrincipalInvestigator().firstName}} {{SiteInfoComponent?.getPrincipalInvestigator().lastName}}
                          }
                      </p>
                  </div>
              </div>
          }

        <div class="d-flex justify-content-between mt-5">
          <button mat-button matStepperPrevious class="btn btn-secondary">
            <i class="bi bi-arrow-left me-1"></i> Back
          </button>
          <button mat-button class="btn btn-success" (click)="onSubmit()">Submit</button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
