import {Component, inject, ViewChild} from "@angular/core";

import {StudyInformationComponent} from "../../components/studies/study-information/study-information.component";
import {DataCollectionComponent} from "../../components/studies/data-collection/data-collection.component";
import {
  InformedConsentFormComponent
} from "../../components/studies/informed-consent-form/informed-consent-form.component";
import {StudyOverviewDataComponent} from "../../components/studies/study-overview-data/study-overview-data.component";
import {
  ScreeningQuestionnaireComponent
} from "../../components/studies/screening-questionnaire/screening-questionnaire.component";
import {HttpClientModule} from "@angular/common/http";
import {ApiService} from "../../api.service";
import {AddNewStudyComponent} from "../add-new-study/add-new-study.component";
import {ActivatedRoute, RouterLink} from "@angular/router";

@Component({
  selector: "app-study-overview",
  standalone: true,
  imports: [
    StudyInformationComponent,
    DataCollectionComponent,
    InformedConsentFormComponent,
    StudyOverviewDataComponent,
    ScreeningQuestionnaireComponent,
    HttpClientModule,
    RouterLink
  ],
  providers: [ApiService],
  templateUrl: "./study-overview.component.html",
  styleUrl: "./study-overview.component.scss",
})
export class StudyOverviewComponent extends AddNewStudyComponent {

  activeRoute = inject(ActivatedRoute);
  studyId!: any;
  informationOfObject: any = {};
  siteLists: any = {};

  @ViewChild(StudyOverviewDataComponent) studyOverviewDataComponent: StudyOverviewDataComponent;

  ngOnInit(): void {
    this.studyId = this.activeRoute.snapshot.params['id'] ? Number(this.activeRoute.snapshot.params['id']) : null;
  }

  ngAfterViewInit(): void {

    if (this.studyId) {
      this.api.getStudyById(this.studyId).subscribe(res => {
        this.informationOfObject = res;
        this.StudyInformationComponent.studyInformationForm.patchValue({
          ...res.data,
          sites: res.data.sites.map(t => t.id),
          startDate: this.formatDateToMMDDYYYY(new Date(res.data.startDate)),
          plannedEndDate: this.formatDateToMMDDYYYY(new Date(res.data.plannedEndDate)),
          readOnlyduration: res.data.durationInWeekPerParticipant == undefined
        });
        this.StudyInformationComponent.studyInformationForm.get("shortCode").disable()
      });
      this.refreshSiteList()
      this.api.getForms(this.studyId).subscribe(res => {
        this.DataCollectionComponent.selectedMeasurement = res?.data.filter(f => f.type == 'measurements')[0]?.form?.id;
      });
    }


    setTimeout(() => {
      this.DataCollectionComponent.videodiarytopicsObject = this.informationOfObject.data.studyVideoDiaryTopics.map(d => {
        return d = {
          topicId: `${d.videoDiaryTopic.id}-${d.videoDiaryTopic.name}`,
          durationSecs: d.duration
        }
      });
    }, 2000);

  }

  refreshTable() {
    this.studyOverviewDataComponent.datatable.recalculate()
  }

  refreshSiteList() {
    this.api.getStudySiteById(this.studyId).subscribe(res => {
      this.siteLists = res;
      this.StudyInformationComponent.studyInformationForm.patchValue({
        sites: this.siteLists.data.map(t => t.id),
      })
      window.dispatchEvent(new Event('resize'));
    });
  }

  screeingInfo() {
    this.informationOfObject.data.studyMeasurements.filter(fl => fl.measurement.type == "On-site").forEach(d => {
      this.DataCollectionComponent.onsiteLists.find(f => f.id == d.measurement.id)['checkbox'] = true;
    });
    this.DataCollectionComponent.videodiarytopicsObject = this.informationOfObject.data.studyVideoDiaryTopics.map(d => {
      return d = {
        topicId: `${d.videoDiaryTopic.id}-${d.videoDiaryTopic.name}`,
        durationSecs: d.duration
      }
    });

  }

  formatDateToMMDDYYYY(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  studyUpdate() {
    if (this.StudyInformationComponent.studyInformationForm.valid) {
      this.api.putStudy({
        "name": this.StudyInformationComponent.studyInformationForm.value.name,
        "description": this.StudyInformationComponent.studyInformationForm.value.description,
        "shortCode": this.StudyInformationComponent.studyInformationForm.value.shortCode,
        "startDate": this.StudyInformationComponent.studyInformationForm.value.startDate,
        "plannedEndDate": this.StudyInformationComponent.studyInformationForm.value.plannedEndDate,
        "plannedNumberOfParticipants": Number(this.StudyInformationComponent.studyInformationForm.value.plannedNumberOfParticipants),
        "durationInWeeksPerParticipant": this.StudyInformationComponent.studyInformationForm.value.durationInWeekPerParticipant,
        // "icfParticipant": this.InformedConsentFormComponent.icfParticipant,
        // "icfCarer": this.InformedConsentFormComponent.icfCarer,
        // "icfStudyManager": this.InformedConsentFormComponent.icfStudyManager,
        "sites": this.StudyInformationComponent.studyInformationForm.value.sites,
        "studyTeamMembers": this.StudyInformationComponent.studyInformationForm.value.studyTeamMembers,
        "studyMeasurements": [],
        "studyVideoDiaryTopics": null,
        "studySurveys": [],
        "studyWearables": [],
        "icfClauses": null
      }, this.studyId).subscribe(res => {
        if (res && res.data) {
          this.refreshSiteList()
          this.toastr.success(res?.message, ' ');
        } else {
          this.toastr.error(res.error, ' ');
        }
      }, err => {
        this.toastr.error(err.error.error, ' ');
      });
    } else {
      this.StudyInformationComponent.studyInformationForm.markAllAsTouched()
      this.toastr.error("Please provide a value for all the required fields");
      this.StudyInformationComponent.errorMessage = true;
    }
  }

  screeningUpdate() {
    if (this.ScreeningQuestionnaireComponent.screenings && this.ScreeningQuestionnaireComponent.demographics) {
      this.api.postForms({
        "forms": [
          {
            "id": [Number(this.ScreeningQuestionnaireComponent.screenings)],
            "type": "screenings"
          },
          {
            "id": [Number(this.ScreeningQuestionnaireComponent.demographics)],
            "type": "demographics"
          }
        ]
      }, this.studyId).subscribe(res => {
        if (res && res.data) {

          this.toastr.success(res?.message, ' ');
        } else {
          this.toastr.error(res.error, ' ');
        }
      }, err => {
        this.toastr.error(err.error.error, ' ');
      });
    } else {
      this.toastr.error("Please try again");
      this.StudyInformationComponent.errorMessage = true;
    }
  }

  collectionUpdate() {
    const inAppLists = this.DataCollectionComponent?.inAppLists.filter(e => e.checkbox == true);
    const onsiteLists = this.DataCollectionComponent?.onsiteLists.filter(e => e.checkbox == true);
    if (
      inAppLists.length == 0 ||
      onsiteLists.length == 0 ||
      this.DataCollectionComponent.selectedMeasurement == "" ||
      !this.DataCollectionComponent.checkVideoDiaryObject()
    ) {
      this.toastr.error('Please provide a value for all the required fields');
    } else {
      this.api.putStudy({
        "startDate": null,
        "plannedEndDate": null,
        "icfClauses": null,
        durationInWeeksPerParticipant: this.StudyInformationComponent.studyInformationForm.value.durationInWeekPerParticipant,
        "studyTeamMembers": this.StudyInformationComponent.studyInformationForm.value.studyTeamMembers,
        "studyMeasurements": [
          ...this.DataCollectionComponent.inAppLists.map(e => {
            return e = {
              id: e.id,
              active: e.checkbox
            }
          }),
        ],
        "studyVideoDiaryTopics": [
          ...this.DataCollectionComponent.videodiarytopicsObject.map(e => {
            return e = {
              "id": Number(e.topicId.split('-')[0]),
              "duration": Number(e.durationSecs)
            }
          }),
        ],
        "studySurveys": [
          ...this.DataCollectionComponent.onsiteLists.map(e => {
            return e = {
              id: e.id,
              active: e.checkbox
            }
          }),
        ],
        "studyWearables": [
          {
            "id": 2,
            "active": false
          }
        ],
      }, this.studyId).subscribe(res => {
        if (res && res.data) {

          this.toastr.success(res?.message, ' ');
        } else {
          this.toastr.error(res.error, ' ');
        }
      }, err => {
        this.toastr.error(err.error.error, ' ');
      });

      this.api.postForms({
        "forms": [
          {
            "id": [Number(this.DataCollectionComponent.selectedMeasurement)],
            "type": "measurements"
          },
        ]
      }, this.studyId).subscribe(res => {
        if (res && res.data) {

          this.toastr.success(res?.message, ' ');
        } else {
          this.toastr.error(res.error, ' ');
        }
      }, err => {
        this.toastr.error(err.error.error, ' ');
      });
    }
  }
}
