<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">Study Overview</h5>
</div>

<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-6">
    <div class="progress" role="progressbar" aria-label="Info striped example" aria-valuenow="60" aria-valuemin="0"
      aria-valuemax="100">
      <div class="progress-bar progress-bar-striped bg-info" [style.width]="percentageSyle"></div>
    </div>
  </div>
  <div class="col-md-6 col-xl-5">
    <p class="mt-2 mt-md-0 mb-3 mb-md-0">{{percentageSyle}}</p>
  </div>
</div>

<h5 class="mt-5">Sites</h5>
<hr class="mt-2 mb-3" />

<ngx-datatable #datatable class="bootstrap" [rows]="rows"
  [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
  [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
  [offset]="page.pageNumber" (page)="setPage($event)">
  <ngx-datatable-column prop="name" name="Site Name" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.name }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="participantsRecruited" name="Participants Recruited" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.participantsRecruited }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="id" name="Action" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a [routerLink]="['/edit-site', row.id]" class="btn btn-outline-success px-3">
        View details
      </a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<h5 class="mt-5">Participants</h5>
<hr class="mt-2 mb-3" />
<participants-table [studyId]="studyId" [columns]="participantColumns"></participants-table>

<div class="text-end mt-5"
  [permission]="Permissions.DELETE_STUDY">
  <button class="text-danger btn" data-bs-toggle="modal" data-bs-target="#cancelVisit">
      <i class="bi bi-x-circle-fill me-1"></i> Delete Study
  </button>
</div>

<!-- Delete study -->
<div class="modal fade" id="cancelVisit" tabindex="-1" aria-labelledby="cancelVisitLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="cancelVisitLabel">
          <i class="bi bi-exclamation-triangle me-1"></i> Delete study
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <p>
          You are about to delete a study. This action cannot be undone. Are you
          sure you want to proceed?
        </p>

        <div class="d-flex justify-content-end gap-2 mt-5">
          <button type="button" class="btn btn-danger" (click)="deleteStudy()">Yes, delete</button>
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
            No, Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
