<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">Study Information</h5>
</div>
<hr class="mt-2 mb-4" />
<form [formGroup]="studyInformationForm">
  <section>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Study Title *</label>
          <input type="text" class="form-control" formControlName="name" placeholder="Enter study name" />
          @if(studyInformationForm.get('name').invalid && (studyInformationForm.get('name').dirty || studyInformationForm.get('name').touched  || errorMessage  )){
            <div class="text-danger">This field is required.</div> 
          }
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Study Code *</label>
          <input type="text" class="form-control" formControlName="shortCode" placeholder="Enter your study code" />
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="form-label">Study Description *</label>
      <textarea class="form-control" rows="3" formControlName="description" placeholder="Enter study description"></textarea>
      @if(studyInformationForm.get('description').invalid && (studyInformationForm.get('description').dirty || studyInformationForm.get('description').touched  || errorMessage  )){
        <div class="text-danger">This field is required.</div> 
      }
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Start Date *</label>
          <input type="date" class="form-control" formControlName="startDate" (change)="startDateChanged()" />
          @if(studyInformationForm.get('startDate').invalid && (studyInformationForm.get('startDate').dirty || studyInformationForm.get('startDate').touched  || errorMessage  )){
            <div class="text-danger">This field is required.</div>
          }
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Planned End Date *</label>
          <input type="date" class="form-control" formControlName="plannedEndDate" />
          @if(studyInformationForm.get('plannedEndDate').invalid && (studyInformationForm.get('plannedEndDate').dirty || studyInformationForm.get('plannedEndDate').touched  || errorMessage  )){
            <div class="text-danger">This field is required and must be greater than start date.</div>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Planned Number of Participants *</label>
          <input type="number" class="form-control" formControlName="plannedNumberOfParticipants" placeholder="Enter planned number of participants" />
          @if(studyInformationForm.get('plannedNumberOfParticipants').invalid && (studyInformationForm.get('plannedNumberOfParticipants').dirty || studyInformationForm.get('plannedNumberOfParticipants').touched  || errorMessage  )){
            <div class="text-danger">This field is required.</div> 
          }
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Study Duration per Participant (Weeks)</label>
          <input type="number" class="form-control" formControlName="durationInWeekPerParticipant" (change)="changedDuration()" placeholder="Enter number of weeks" />
        </div>
      </div>

      <div class="col-md-6 offset-md-6">
        <div class="form-check mb-3">
          <input class="form-check-input" formControlName="readOnlyduration" type="checkbox" id="duration" (change)="changedNoDefindedDuration()"/>
          <label class="form-check-label" for="duration">
            Duration not defined
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Connect Study to Sites</label>
            <ng-select [multiple]="true" [dropdownPosition]="'top'" placeholder="Select one or multiple Sites" formControlName="sites">
                @for (item of sites; track $index) {
                    <ng-option [value]="item.id">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
      </div>
    </div>
  </section>
</form>