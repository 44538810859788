<div class="not-found card" style="text-align: center; margin-top: 50px;">
  <h1 class="number">404</h1>

  <img class="stethoscope" src="/assets/images/stethoscope.gif" alt="stethoscope">
  <h2>Page Not Found</h2>
  <p>Sorry, the page you are looking for does not exist.</p>

  <a [routerLink]="['/']" class="btn btn-outline-secondary py-3">
    Go Back
  </a>
</div>
