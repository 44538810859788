import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgbNav, NgbNavContent, NgbNavItem, NgbNavLinkButton, NgbNavOutlet} from "@ng-bootstrap/ng-bootstrap";
import {ChangePasswordComponent} from "@components/profile/change-password.component";
import {LogginedUser} from "@models/user.model";
import {AuthService} from "@services/auth.service";
import {PersonalInfoComponent} from "@components/profile/personal-info.component";
import {NgIf} from "@angular/common";
import {SpinnerComponent} from "@components/spinner/spinner.component";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavLinkButton,
    RouterLink,
    NgbNavContent,
    NgbNavOutlet,
    ChangePasswordComponent,
    PersonalInfoComponent,
    NgIf,
    SpinnerComponent
  ],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {

  user: LogginedUser

  constructor(
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.authService.loggedUser$.subscribe((user: LogginedUser) => {
      this.user = user
    })
  }

}
