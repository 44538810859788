import { Component } from '@angular/core';
import { ApexChart, NgApexchartsModule } from "ng-apexcharts";
import { IcfClause, Study } from "../../../../api/api.interfaces";
import { ApiService } from "../../../../api.service";
import { DatePipe, NgIf } from "@angular/common";
import { WithdrawnComponent } from "../withdrawn/withdrawn.component";
import { CompleteComponent } from "../complete/complete.component";
import { Participant } from '@models/participant.model';

@Component({
  selector: 'study-overview',
  standalone: true,
  imports: [
    NgApexchartsModule,
    DatePipe,
    NgIf,
    WithdrawnComponent,
    CompleteComponent
  ],
  templateUrl: './study-overview.component.html',
})
export class StudyOverviewComponent {

  participant: Participant
  study: Study
  icfClauses: Map<number, IcfClause> = new Map()

  chart: { series: Array<Number>, chart: ApexChart, labels: Array<String> } = {
    series: [0, 0],
    chart: {
      type: 'pie',
      width: 300,
    },
    labels: ['Scheduled', 'Completed'],
  }

  constructor(
    private apiService: ApiService
  ) {
  }

  refresh(participant: Participant) {
    this.participant = participant
    this.chart.series = [this.participant.totalTasksScheduled, this.participant.totalTasksCompleted]
    this.apiService.getStudy(this.participant.studySite.study.id).subscribe(response => {
      this.study = response.data
      this.study.icfClauses.forEach(item => {
        this.icfClauses.set(item.id, item)
      })
    })
  }
}
