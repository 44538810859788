import {Component} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../api.service';
import {NgSelectModule} from "@ng-select/ng-select";

@Component({
  selector: 'app-site-info',
  standalone: true,
  imports: [NgbTooltipModule, FormsModule, RouterLink, ReactiveFormsModule, NgSelectModule],
  providers: [ApiService],
  templateUrl: './site-info.component.html',
  styleUrl: './site-info.component.scss'
})
export class SiteInfoComponent {
  countrieList:any[]=[];
  studyLists:any[]=[];
  siteForm!: FormGroup;
  errorMessage= false;
  siteId: number;
  studyListInfo:any[]=[];
  memberListInfo:any[]=[];

  countryName!: string;
  teamMembersdata: any[] = [];

  constructor(
      private api: ApiService,
      private fb: FormBuilder,
      private apiService: ApiService
  ) {
    this.countries();
    this.studyListing();
  }

  ngOnInit(): void {
    this.apiService.studyManagerListing().subscribe(res => {
      this.teamMembersdata = res?.data;
    });

    this.siteForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      countryCode: [null, Validators.required],
      studies: [[]],
      members: [[]],
      principalInvestigator: [null]
    });
    this.siteForm.get("principalInvestigator").disable()
  }


  countries() {

    this.api.countries().subscribe(
      (res: any) => {
        this.countrieList = res;
      },
      error => {

      }
    );
  }


  
  studyListing() {

    this.api.studyListing({
      sort: "id",
      start: 0,
      size: 90000,
    }).subscribe(
      (res: any) => {
        this.studyLists = res.data;
      },
      error => {

      }
    );
  }

  onSubmit() {
  }

  teamMembersChanged() {
    this.memberListInfo = this.teamMembersdata.filter(f => this.siteForm.value.members.includes(f.id.toString()));
    if (this.memberListInfo.length == 0) {
      this.siteForm.get("principalInvestigator").disable()
    } else {
      this.siteForm.get("principalInvestigator").enable()
    }

    const selectedId = this.siteForm.get("principalInvestigator").value
    let valueInList = false
    this.memberListInfo.forEach((member) => {
      if (member.id == selectedId) {
        valueInList = true
      }
    })
    if (!valueInList) {
      this.siteForm.get("principalInvestigator").setValue(null)
    }
  }

  getPrincipalInvestigator() {
    const selectedId = this.siteForm.get("principalInvestigator").value
    let selected = null
    this.memberListInfo.forEach((member) => {
      if (member.id == selectedId) {
        selected = member
      }
    })
    return selected
  }


  getstudyListInfo(){
    this.studyListInfo = this.studyLists.filter(f => this.siteForm.value.studies.includes(f.id.toString()));
    this.memberListInfo = this.teamMembersdata.filter(f => this.siteForm.value.members.includes(f.id.toString()));
    this.countryName = this.countrieList.filter(f => f.code == this.siteForm.value.countryCode)[0]?.name;
  }

}
