

<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/participant-list">Participants</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ participant?.participantCode }}</li>
    </ol>
</nav>

<div class="card">
    <h4 class="card-header d-flex justify-content-between">
        <span>{{ participant?.participantCode }}</span>

        <span class="badge text-bg-success" *ngIf="participant?.status.status == 'Active'">Active</span>
        <span class="badge text-bg-info" *ngIf="participant?.status.status == 'Completed'">Completed</span>
        <span class="badge text-bg-danger" *ngIf="participant?.status.status == 'Withdrawn'">Withdrawn</span>
    </h4>
    <div class="card-body">
        <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" (shown)="tabChanged($event)" class="nav-tabs">
            <li [ngbNavItem]="'study-tab'">
                <button ngbNavLink>
                    <i class="bi bi-info-circle"></i> Study Overview
                </button>
                <ng-template ngbNavContent>
                    <study-overview></study-overview>
                </ng-template>
            </li>
            <li [ngbNavItem]="'info-tab'">
                <button ngbNavLink>
                    <i class="bi bi-info-circle"></i> Personal Information
                </button>
                <ng-template ngbNavContent>
                    <personal-info></personal-info>
                </ng-template>
            </li>
            <li [ngbNavItem]="'question-tab'">
                <button ngbNavLink>
                    <i class="bi bi-file-earmark-check"></i> Screening &amp; demographics
                </button>
                <ng-template ngbNavContent>
                    <h5 class="mb-0 pt-4 mt-4">Screening form</h5>
                    <hr class="mt-2 mb-4"/>

                    <div class="mb-4 pb-4">
                        <questionnaire-form #screeningForm></questionnaire-form>
                    </div>

                    <h5 class="mb-0 pt-4 mt-4">Demographics form</h5>
                    <hr class="mt-2 mb-4"/>

                    <div class="mb-4">
                        <questionnaire-form #demographicsForm></questionnaire-form>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="'sessions-tab'"
                    [permission]="Permissions.LIST_SESSIONS">
                <button ngbNavLink>
                    <i class="bi bi-person"></i> Sessions
                </button>
                <ng-template ngbNavContent>
                    <participant-sessions></participant-sessions>
                </ng-template>
            </li>
            <li [ngbNavItem]="'adverse-tab'"
                    [permission]="Permissions.LIST_ADVERSE_EVENTS">
                <button ngbNavLink>
                    <i class="bi bi-file-earmark-check"></i> Adverse events
                </button>
                <ng-template ngbNavContent>
                    <adverse-events [disabled]="participant?.status.status != 'Active'"></adverse-events>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
