import {Component} from '@angular/core';
import {ParticipantsTableComponent} from "@components/participants/participants-table/participants-table.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-participant-list',
  standalone: true,
  imports: [ParticipantsTableComponent, RouterLink],
  templateUrl: './participant-list.component.html',
  styleUrl: './participant-list.component.scss',
})
export class ParticipantListComponent{

}
