<div class="auth-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="card h-auto">
          <div class="card-body">
            <div class="text-center mb-4">
              <a
                ><img class="logo-auth" src="assets/images/logo.svg" alt="logo"
              /></a>
            </div>
              <spinner *ngIf="loading"></spinner>
            <h4 class="text-center mb-4">Sign In</h4>
            <form  [formGroup]="signinForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-3">
                <label class="form-label" for="username">Email</label>
                <input
                  type="text"
                  id="username"
                  formControlName="email"
                  class="form-control"
                  placeholder="Enter your email here"
                />
                @if(signinForm.get('email').invalid && (signinForm.get('email').dirty || signinForm.get('email').touched)){
                  <div class="text-danger">This field is required and must be valid.</div>
                }
              </div>
              <div class="form-group mb-3 mb-sm-4">
                <label class="form-label">Password</label>

                <input
                  type="password"
                  formControlName="password"
                  class="form-control"
                  value="Password"
                  placeholder="Enter your password here"
                />
                @if(signinForm.get('password').invalid && (signinForm.get('password').dirty || signinForm.get('password').touched)){
                  <div class="text-danger">This field is required.</div>
                }
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  [disabled]="signinForm.invalid"
                >
                  Submit
                </button>
              </div>
              <div class="text-center">
                <a [routerLink]="['/forgot-password']"
                  [queryParams]="{
                    email: signinForm.value.email
                  }"
                  class="btn btn-link py-3 btn-link-small">
                  Forgot your password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<spinner *ngIf="loading"></spinner>
