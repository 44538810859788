<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">Confirmation</h5>
  <a
    href="#"
    tooltipClass="custom-tooltip"
    ngbTooltip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    Lorem Ipsum has been the industry's standard dummy"
  >
    <i class="bi bi-info-circle"></i>
  </a>
</div>

<hr class="mt-2 mb-4" />

<div class="row mb-3">
  <div class="col-md-6">
    <span class="fw-medium">Study Name:</span> Demo study name
  </div>
  <div class="col-md-6">
    <span class="fw-medium">Study short code:</span> UH-MON
  </div>
</div>

<h6>Study description:</h6>
<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type
  specimen book. It has survived not only five centuries,
</p>

<div class="row mt-4">
  <div class="col-md-6">
    <p><span class="fw-medium">Start date:</span> Mon 25 March 2024</p>
    <p><span class="fw-medium">Planned number of participant:</span> 150</p>
  </div>
  <div class="col-md-6">
    <p><span class="fw-medium">Planned end date:</span> Mon 25 March 2024</p>
    <p>
      <span class="fw-medium">Study duration per participant (weeks):</span>
      No duration defined
    </p>
  </div>
</div>

<h5 class="mt-5">Data Collection and Schedule Settings</h5>
<hr class="mt-2 mb-3" />

<div class="row">
  <div class="col-md-4">
    <p class="text-success fw-medium mb-1">On-site measurements</p>
    <ul>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Weight
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Height
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Heart rate variabilty
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Heart rate
      </li>
    </ul>

    <p class="text-success fw-medium mb-1 mt-4 pt-2">
      Video diary topics battery
    </p>

    <ul>
      <li>Question 1, duration X seconds</li>
      <li>Question 6, duration X seconds</li>
    </ul>
  </div>

  <div class="col-md-4">
    <p class="text-success fw-medium mb-1 mt-4 mt-md-0">
      In-app digital measurements
    </p>
    <ul>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Facial analysis
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Video diary
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Fingertip analysis
      </li>
    </ul>
  </div>

  <div class="col-md-4">
    <p class="text-success fw-medium mt-4 mt-md-0 mb-1">
      In-app scales/surveys
    </p>
    <ul>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Anxiety Single Question
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        Depression Single question
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        GAD-7 scale
      </li>
      <li>
        <img
          class="me-1"
          width="20"
          src="assets/images/check-mark.png"
          alt="check"
        />
        EDE
      </li>
    </ul>
  </div>
</div>

<h5 class="mt-5">Informed Consent Form for participant</h5>
<hr class="mt-2 mb-3" />

<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type
  specimen book. It has survived not only five centuries,
</p>

<p class="fw-medium mb-2 mt-4">Additional clauses</p>
<p class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing...</p>
<p class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing...</p>

<h5 class="mt-5">Informed Consent Form for parent/carer</h5>
<hr class="mt-2 mb-3" />

<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type
  specimen book. It has survived not only five centuries,
</p>

<p class="fw-medium mb-2 mt-4">Additional clauses</p>
<p class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing...</p>

<h5 class="mt-5">Study Manager Control Check</h5>
<hr class="mt-2 mb-3" />

<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type
  specimen book. It has survived not only five centuries,
</p>

<h5 class="mt-5">Screening Form</h5>
<hr class="mt-2 mb-3" />

<p>
  <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
  Monitoring Study Screening Form
</p>
