<h5 class="mt-2 mb-0">Study Information</h5>
<hr class="mt-2 mb-4"/>

<div class="row mb-4">
    <div class="col-md-6">
        <p><span class="fw-medium">Study:</span> {{ study?.name }} ({{ study?.shortCode }})</p>
        <p><span class="fw-medium">Start Date:</span>
            {{ participant?.startDate == undefined? 'N/A' : participant?.startDate | date:'d MMMM y' }}
        </p>
    </div>
    <div class="col-md-6">
        <p><span class="fw-medium">Study Site:</span> {{ participant?.studySite.site.name }}</p>
        <p>
            <span class="fw-medium">Week:</span>
            @if (participant?.currentWeekNumber == undefined) {
                N/A
            } @else {
                {{ participant?.currentWeekNumber }}
                @if (study?.durationInWeekPerParticipant != undefined) {
                    / {{ study?.durationInWeekPerParticipant }}
                }
            }
        </p>
    </div>
</div>


<h5 class="mt-2 mb-0">Informed Consent Forms</h5>
<hr class="mt-2 mb-4"/>

<div class="row">
    <div class="col-4"><b>- Informed Consent Form for Participant</b></div>
    <div class="col"><span class="text-green px-2"><b>SIGNED</b></span></div>
</div>
<div class="row mt-2" *ngIf="participant?.icfCarerSignedAt != undefined">
    <div class="col-4"><b>- Informed Consent Form for Parent/Carer</b></div>
    <div class="col"><span class="text-green px-2"><b>SIGNED</b></span></div>
</div>

<div class="d-flex justify-content-between mt-5" *ngIf="participant?.status.status == 'Active'">
    <complete [participantId]="participant?.id"></complete>
    <withdrawn [participantId]="participant?.id"></withdrawn>
</div>