Participant Code Assigned: <b>{{ participant?.participantCode }}</b>

<questionnaire-form></questionnaire-form>

<div class="mt-4 d-flex justify-content-between">
    <button type="button" class="btn btn-outline-info" (click)="prev()">
        <i class="bi bi-arrow-left ms-1"></i> Prev
    </button>
    <div>
        <withdrawn [participantId]="participant?.id"></withdrawn>
        <button type="button" class="btn btn-success ms-2" (click)="submitForm()">
            Next <i class="bi bi-arrow-right ms-1"></i>
        </button>
    </div>
</div>
