<h5 class="mb-0">Study Site Information</h5>
<hr class="mt-2 mb-4" />
<form [formGroup]="siteForm" (ngSubmit)="onSubmit()">
<div class="row">
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Site Name *</label>
      <input type="text" class="form-control"  formControlName="name" placeholder="Enter Site name here" />
      @if(siteForm.get('name').invalid && (siteForm.get('name').dirty || siteForm.get('name').touched  || errorMessage  )){
        <div class="text-danger">This field is required.</div> 
      }
     
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Address *</label>
      <input type="text" class="form-control" formControlName="address" placeholder="Enter Address here" />
      @if(siteForm.get('address').invalid && (siteForm.get('address').dirty || siteForm.get('address').touched  || errorMessage)){
        <div class="text-danger">This field is required.</div> 
      }
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">City *</label>
      <input type="text" class="form-control" formControlName="city" placeholder="Enter City here" />
      <!-- <div *ngIf="siteForm.get('city')?.invalid && (siteForm.get('city')?.dirty || siteForm.get('city')?.touched  || errorMessage)" class="invalid-feedback">
        <div >This field is required.</div>
      </div> -->
      @if(siteForm.get('city').invalid && (siteForm.get('city').dirty || siteForm.get('city').touched  || errorMessage)){
        <div class="text-danger">This field is required.</div> 
      }
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Postal Code *</label>
      <input type="text" class="form-control" formControlName="postalCode" placeholder="Enter Postal Code here" />
      <!-- <div *ngIf="siteForm.get('postalCode')?.invalid && (siteForm.get('postalCode')?.dirty || siteForm.get('postalCode')?.touched  || errorMessage)" class="invalid-feedback">
        <div >This field is required.</div>
      </div> -->
      @if(siteForm.get('postalCode').invalid && (siteForm.get('city').dirty || siteForm.get('postalCode').touched  || errorMessage)){
        <div class="text-danger">This field is required.</div> 
      }
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Country *</label>
        <ng-select placeholder="Select one Country" formControlName="countryCode">
            @for (item of countrieList; track $index) {
                <ng-option value="{{ item.code }}">{{item.name}}</ng-option>
            }
        </ng-select>
      @if(siteForm.get('countryCode').invalid && (siteForm.get('countryCode').dirty || siteForm.get('countryCode').touched  || errorMessage)){
        <div class="text-danger">This field is required.</div> 
      }
    </div>
  </div>
</div>

<h5 class="mt-4 mb-0">Connect Site to Studies</h5>
<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Select Studies</label>
        <ng-select [multiple]="true" [dropdownPosition]="'top'" placeholder="Select one or multiple Studies" formControlName="studies">
            @for (item of studyLists; track $index) {
                <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
            }
        </ng-select>
      @if(siteForm.get('studies').invalid && (siteForm.get('studies').dirty || siteForm.get('studies').touched  || errorMessage)){
        <div class="text-danger">This field is required.</div> 
      }
    </div>
  </div>
</div>

<h5 class="mt-4 mb-0">Team Management</h5>
<hr class="mt-2 mb-4" />
<div class="row">
    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Grant access to the following Site Team Members</label>
            <ng-select [multiple]="true" [dropdownPosition]="'top'" (change)="teamMembersChanged()" placeholder="Select one or multiple Site Team Members" formControlName="members">
                @for (item of teamMembersdata; track $index) {
                    <ng-option value="{{item.id}}">{{item.firstName}} {{item.lastName}}</ng-option>
                }
            </ng-select>
            @if(siteForm.get('members').invalid && (siteForm.get('members').dirty || siteForm.get('members').touched  || errorMessage)){
                <div class="text-danger">This field is required.</div>
            }
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Select el Site's Principal Investigator</label>
            <select class="form-select" formControlName="principalInvestigator">
                <option [value]="null">Select one Team Member</option>
                @for (item of memberListInfo; track $index) {
                    <option [value]="item.id">{{item.firstName}} {{item.lastName}}</option>
                }
            </select>
        </div>
    </div>
</div>

</form>