import { Component } from '@angular/core';

@Component({
  selector: 'app-site-confirmation',
  standalone: true,
  imports: [],
  templateUrl: './site-confirmation.component.html',
  styleUrl: './site-confirmation.component.scss'
})
export class SiteConfirmationComponent {

}
