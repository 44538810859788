<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/study-list">Studies</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{informationOfObject?.data?.name  }} {{informationOfObject?.data?.shortCode  }}</li>
  </ol>
</nav>

<div class="card">
  <h4 class="card-header d-flex justify-content-between">
    <span>{{informationOfObject?.data?.name  }} {{informationOfObject?.data?.shortCode  }}</span>
    <span class="badge text-bg-info">{{ informationOfObject?.data?.status }}</span>
  </h4>
  <div class="card-body">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button (click)="refreshTable()"
          class="nav-link active"
          id="overview-tab"
          data-bs-toggle="tab"
          data-bs-target="#overview-tab-pane"
          type="button"
          role="tab"
          aria-controls="overview-tab-pane"
          aria-selected="false"
        >
          <i class="bi bi-search"></i> Study Overview
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="info-tab"
          data-bs-toggle="tab"
          data-bs-target="#info-tab-pane"
          type="button"
          role="tab"
          aria-controls="info-tab-pane"
          aria-selected="true"
        >
          <i class="bi bi-info-circle"></i> Study Information
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="screening-tab"
          data-bs-toggle="tab"
          data-bs-target="#screening-tab-pane"
          type="button"
          role="tab"
          aria-controls="screening-tab-pane"
          aria-selected="true"
        >
          <i class="bi bi-file-earmark-check"></i> Available Pre-built Forms
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="consent-tab"
          data-bs-toggle="tab"
          data-bs-target="#consent-tab-pane"
          type="submit"
          role="tab"

          aria-controls="consent-tab-pane"
          aria-selected="false"
        >
          <i class="bi bi-file-earmark-check"></i> Informed Consent Forms
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="collection-tab"
          data-bs-toggle="tab"
          data-bs-target="#collection-tab-pane"
          type="button"
          role="tab"
          aria-controls="collection-tab-pane"
          aria-selected="false"
        >
          <i class="bi bi-gear"></i> Data Collection
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="overview-tab-pane"
        role="tabpanel"
        aria-labelledby="overview-tab"
        tabindex="0"
      >
        <app-study-overview-data [informationOfObject]="informationOfObject"  [studyId]="studyId" [siteLists]="siteLists"></app-study-overview-data>
      </div>
      <div
        class="tab-pane fade"
        id="info-tab-pane"
        role="tabpanel"
        aria-labelledby="info-tab"
        tabindex="0"
      >
        <app-study-information></app-study-information>
        <div class="text-end mt-4">
          <!-- <span class="text-danger me-4 me-md-5"
            ><i class="bi bi-x-circle-fill me-1"></i> Delete Study</span
          > -->
          <button class="btn btn-success" (click)="studyUpdate()">Update</button>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="screening-tab-pane"
        role="tabpanel"
        aria-labelledby="screening-tab"
        tabindex="0"
      >
        <app-screening-questionnaire [studyId]="studyId"></app-screening-questionnaire>
        <div class="text-end mt-4">
          <!-- <span class="text-danger me-4 me-md-5"
            ><i class="bi bi-x-circle-fill me-1"></i> Delete Study</span
          > -->
          <button class="btn btn-success" (click)="screeningUpdate()">Update</button>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="consent-tab-pane"
        role="tabpanel"

        aria-labelledby="consent-tab"
        tabindex="0"
      >
        <app-informed-consent-form [studyId]="studyId"></app-informed-consent-form>
      </div>

      <div
        class="tab-pane fade"
        id="collection-tab-pane"
        role="tabpanel"
        aria-labelledby="collection-tab"
        tabindex="0"
      >
        <app-data-collection [editing]="true" [study]="informationOfObject?.data"></app-data-collection>
        <div class="text-end mt-4">
          <button class="btn btn-success" (click)="collectionUpdate()">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>
