<spinner *ngIf="loading"></spinner>

<div [hidden]="loading">
    Participant Code Assigned: <b>{{ participant?.participantCode }}</b>

    <questionnaire-form></questionnaire-form>

    @if (isRejected) {
        <div class="alert alert-warning mt-4" role="alert">
            Participant does not match the inclusion criteria
        </div>
    }

    <div class="text-end mt-4">
        <button type="button" class="btn btn-success" (click)="submitForm()" [disabled]="isRejected">
            Next <i class="bi bi-arrow-right ms-1"></i>
        </button>
    </div>
</div>
