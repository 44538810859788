<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/site-list">Sites</a></li>
    <li class="breadcrumb-item active" aria-current="page">UH-SITE-001</li>
  </ol>
</nav>

<div class="card add-new-site">
  <h4 class="card-header d-flex justify-content-between">
    <span>UH-SITE-001</span>
    <span class="badge text-bg-success font-14 py-2"
      >10 participant enrolled</span
    >
  </h4>

  <div class="card-body">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="info-tab"
          data-bs-toggle="tab"
          data-bs-target="#info-tab-pane"
          type="button"
          role="tab"
          aria-controls="info-tab-pane"
          aria-selected="true"
        >
          <i class="bi bi-info-circle"></i> Site Information
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="devices-tab"
          data-bs-toggle="tab"
          data-bs-target="#devices-tab-pane"
          type="button"
          role="tab"
          aria-controls="devices-tab-pane"
          aria-selected="false"
        >
          <i class="bi bi-gear"></i> Clinical devices
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="info-tab-pane"
        role="tabpanel"
        aria-labelledby="info-tab"
        tabindex="0"
      >
        <app-site-overview-info></app-site-overview-info>
        <div class="text-end mt-4">
          <span class="text-danger me-4 me-md-5"
            ><i class="bi bi-x-circle-fill me-1"></i> Delete Site</span
          >
          <button class="btn btn-success">Update</button>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="devices-tab-pane"
        role="tabpanel"
        aria-labelledby="devices-tab"
        tabindex="0"
      >
        <app-clinical-devices></app-clinical-devices>
      </div>
    </div>
  </div>
</div>
