<div [formGroup]="formGroup">
    <div class="d-flex justify-content-between align-items-center mt-2">
        <h5 class="mb-0">Informed Consent Form for Participant (Adult) *</h5>
    </div>
    <hr class="mt-2 mb-4"/>
    <div class="form-group mb-3">
        <quill-editor [styles]="{height: '150px'}" formControlName="icfParticipant" placeholder="Informed consent for participant"></quill-editor>
    </div>

    <h6>Additional Clauses</h6>
    <hr class="mt-2 mb-4"/>
    @for (item of participantList; track $index) {
        <div class="row">
            <div class="col-md-10">
                <div class="mb-3" [innerHTML]="item"></div>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn text-danger" (click)="removeParticipant($index)">
                    <span class="bi bi-x-circle me-1"></span> Remove clause
                </button>
            </div>
        </div>
    }
    <div class="row">
        <div class="col-md-10">
            <div class="form-group mb-3">
                <quill-editor [styles]="{height: '150px'}" formControlName="icfAdditionalParticipant" placeholder="Add here your additional clause"></quill-editor>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn" (click)="addParticipant()">
                <span class="bi bi-plus-circle me-1"></span> Add clause
            </button>
        </div>
    </div>


    <div class="d-flex justify-content-between align-items-center mt-2">
        <h5 class="mb-0">Informed Consent Form for Participant (Children) (*)</h5>
    </div>
    <hr class="mt-2 mb-4"/>
    <div class="form-group mb-3">
        <quill-editor [styles]="{height: '150px'}" formControlName="icfParticipantAdolescent" placeholder="Informed consent for participant"></quill-editor>
    </div>

    <h6>Additional Clauses</h6>
    <hr class="mt-2 mb-4"/>
    @for (item of participantAdolescentList; track $index) {
        <div class="row">
            <div class="col-md-10">
                <div class="form-group mb-3" [innerHTML]="item"></div>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn text-danger" (click)="removeParticipantAdolescent($index)">
                    <span class="bi bi-x-circle me-1"></span> Remove clause
                </button>
            </div>
        </div>
    }
    <div class="row">
        <div class="col-md-10">
            <div class="form-group mb-3">
                <quill-editor [styles]="{height: '150px'}" formControlName="icfAdditionalParticipantAdolescent" placeholder="Add here your additional clause"></quill-editor>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn" (click)="addParticipantAdolescent()">
                <span class="bi bi-plus-circle me-1"></span> Add clause
            </button>
        </div>
    </div>


    <div class="d-flex justify-content-between align-items-center mt-2">
        <h5 class="mb-0">Informed Consent Form for Parent/Carer <small class="fw-normal">(Only requested when participant is younger than 16 years old)</small> (*)</h5>
    </div>
    <hr class="mt-2 mb-4"/>
    <div class="form-group mb-3">
        <quill-editor [styles]="{height: '150px'}" formControlName="icfCarer" placeholder="Informed consent for parent/carer"></quill-editor>
    </div>

    <h6>Additional Clauses</h6>
    <hr class="mt-2 mb-4"/>
    @for (item of carerList; track $index) {
        <div class="row">
            <div class="col-md-10">
                <div class="form-group mb-3" [innerHTML]="item"></div>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn text-danger" (click)="removeCarer($index)">
                    <span class="bi bi-x-circle me-1"></span> Remove clause
                </button>
            </div>
        </div>
    }
    <div class="row">
        <div class="col-md-10">
            <div class="form-group mb-3">
                <quill-editor [styles]="{height: '150px'}" formControlName="icfAdditionalCarer" placeholder="Add here your additional clause"></quill-editor>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn" (click)="addCarer()">
                <span class="bi bi-plus-circle me-1"></span> Add clause
            </button>
        </div>
    </div>


    <div class="d-flex justify-content-between align-items-center mt-2">
        <h5 class="mb-0">Control Check for Study Researcher (*)</h5>
    </div>
    <hr class="mt-2 mb-4"/>
    <div class="form-group mb-3">
        <quill-editor [styles]="{height: '150px'}" formControlName="icfStudyManager" placeholder="Study Manager Control Check"></quill-editor>
    </div>

    <h6>Additional clauses</h6>
    <hr class="mt-2 mb-4"/>
    @for (item of studyManagerList; track $index) {
        <div class="row">
            <div class="col-md-10">
                <div class="form-group mb-3" [innerHTML]="item"></div>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn text-danger" (click)="removeStudyManager($index)">
                    <span class="bi bi-x-circle me-1"></span> Remove clause
                </button>
            </div>
        </div>
    }
    <div class="row">
        <div class="col-md-10">
            <div class="form-group mb-3">
                <quill-editor [styles]="{height: '150px'}" formControlName="icfAdditionalStudyManager" placeholder="Add here your additional clause"></quill-editor>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn" (click)="addStudyManager()">
                <span class="bi bi-plus-circle me-1"></span> Add clause
            </button>
        </div>
    </div>

</div>
<div class="text-end mt-4" *ngIf="studyId != undefined">
    <button class="btn btn-success" (click)="submitForm()">Update</button>
</div>
