import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';

export const logInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req);
};


@Injectable()
export class SampleInterceptor implements HttpInterceptor {

  has401RecentlyHandled = false;

  constructor(
    public authService: AuthService,
    public toastrService: ToastrService,
    public router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {


    const authToken = this.authService.getToken();
    // Clone the request and add the authorization header
    if (authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        this.has401RecentlyHandled = false;
        return event;
      }),
      catchError(x => this.handleAuthError(x))
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    console.log(err)
    if (!err.url.includes("sign-in")) {

      switch (err.status) {
        case 401:
          if (this.has401RecentlyHandled) {
            return of(err.message); // or EMPTY may be appropriate here
          }
          this.has401RecentlyHandled = true;
          this.authService.clearData();
          this.toastrService.warning('Session expired. Please sign in again');
          this.router.navigate(['/signin']);
          return of(err.message); // or EMPTY may be appropriate here
        case 403:
        case 404:
          this.toastrService.error(err.message);
          return of(err.message); // or EMPTY may be appropriate here
        case 500:
          this.toastrService.error('Something went wrong. Please try again later');
          return of(err.message);
      }
    }
    return throwError(err);
  }
}
