import { Permissions } from '@models/permissions.enum';
import { Component, Input, ViewChild } from '@angular/core';
import { AdverseEvent, Page } from "../../../../api/api.interfaces";
import { ApiService } from "../../../../api.service";
import { DatePipe, NgIf, SlicePipe } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RouterLink } from "@angular/router";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { WithdrawnComponent } from "../withdrawn/withdrawn.component";
import { EditAdverseEventComponent } from "../edit-adverse-event/edit-adverse-event.component";
import { SpinnerComponent } from "@components/spinner/spinner.component";
import { CompleteComponent } from "@components/participants/overview/delete-adverse-event/delete-adverse-event.component";
import { PermissionDirective } from '@components/directives/permission.directive';
import { Participant } from '@models/participant.model';

@Component({
  selector: 'adverse-events',
  standalone: true,
  imports: [
    DatePipe,
    NgxDatatableModule,
    NgIf,
    RouterLink,
    SlicePipe,
    NgbTooltip,
    WithdrawnComponent,
    EditAdverseEventComponent,
    SpinnerComponent,
    CompleteComponent,
    PermissionDirective
  ],
  templateUrl: './adverse-events.component.html',
})
export class AdverseEventsComponent {
  @Input() disabled: boolean
  @ViewChild(EditAdverseEventComponent) editAdverseEvent: EditAdverseEventComponent;

  Permissions = Permissions;
  participant: Participant;
  page: Page
  rows: AdverseEvent[]
  sortField: string = 'startDate'
  order: string = 'desc'
  loading: boolean = false

  constructor(
    private apiService: ApiService
  ) {
  }

  refresh(participant: Participant) {
    this.participant = participant
    this.refreshRows()
  }


  ngOnInit(): void {
    this.page = {
      size: 10,
      start: 0,
      totalCount: 0
    }
  }

  setPage(pageInfo) {
    this.page.start = pageInfo.offset * this.page.size
    this.refreshRows()
  }

  sort(pageInfo) {
    this.sortField = pageInfo.sorts[0].prop
    this.order = pageInfo.sorts[0].dir
    this.refreshRows()
  }

  refreshRows() {
    this.loading = true
    this.apiService.listParticipantAdverseEvents(this.participant.id, this.page.start, this.sortField, this.order).subscribe(res => {
      this.rows = res.data
      this.page = res.pagination
      this.loading = false
    });
  }

  viewEdit(editing: boolean, disabled: boolean, adverseEvent: null | AdverseEvent) {
    this.editAdverseEvent.open(this.participant, editing, disabled, adverseEvent)
  }

  viewDetail(id: number) {

  }
}
