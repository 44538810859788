<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/study-list">Studies</a></li>
    <li class="breadcrumb-item active" aria-current="page">Study List</li>
  </ol>
</nav>

<div class="card">
  <h4 class="card-header">Study List</h4>
  <div class="card-body">
    <ngx-datatable
      class="bootstrap"
      #table
      [rows]="rows"
      [columnMode]="'force'"
      [headerHeight]="50"
      [externalPaging]="true"
      [footerHeight]="30"
      [rowHeight]="'auto'"
      [limit]="page.size"
      [count]="page.totalElements"
      [offset]="page.offset"
      [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
      (page)="setPage($event)"
    >
      <ngx-datatable-column
        prop="name"
        name="Study Title"
        [sortable]="true"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.name }}
        </ng-template>
      </ngx-datatable-column>

        <ngx-datatable-column
                prop="shortCode"
                name="Study Code"
                [sortable]="false"
                [flexGrow]="1"
        >
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.shortCode }}
            </ng-template>
        </ngx-datatable-column>

<!--      <ngx-datatable-column-->
<!--        prop="createdAt"-->
<!--        name="Created On"-->
<!--        [sortable]="true"-->
<!--        [flexGrow]="1"-->
<!--      >-->
<!--        <ng-template let-row="row" ngx-datatable-cell-template>-->
<!--          {{ row?.createdAt | date : "dd/MM/YYYY" }}-->
<!--        </ng-template>-->
<!--      </ngx-datatable-column>-->

      <ngx-datatable-column
        prop="status"
        name="Status"
        [sortable]="true"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span
            class="badge"
            [ngClass]="{
              'text-bg-info': row.status == 'ongoing',
              'text-bg-warning': row.status == 'planned',
              'text-bg-success': row.status == 'completed'
            }"
          >
            {{ row?.status }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="plannedNumberOfParticipants"
        name="Recruitment Status"
        [sortable]="false"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.currentNumberOfParticipants }} /
          {{ row?.plannedNumberOfParticipants }}
        </ng-template>
      </ngx-datatable-column>

      @if (Permissions.VIEW_EDIT_STUDY| hasPermission) {
        <ngx-datatable-column
          prop="id"
          name="Action"
          [sortable]="false"
          [flexGrow]="1"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/study-overview', row.id]"
              class="btn btn-outline-success padd-new-studyx-3"
            >
              View details
            </a>
          </ng-template>
        </ngx-datatable-column>
      }
    </ngx-datatable>
  </div>
</div>

<spinner *ngIf="loading"></spinner>
