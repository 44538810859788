import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {ApiService} from "../../api.service";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {SpinnerComponent} from "@components/spinner/spinner.component";
import {NgClass, NgIf} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {LogginedUser, User} from "@models/user.model";
import {Router} from "@angular/router";
import {AuthService} from "@services/auth.service";

@Component({
  selector: 'personal-info',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SpinnerComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './personal-info.component.html',
})
export class PersonalInfoComponent {
  privateUser: LogginedUser
  loading: boolean = false

  formGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  @Input()
  set user(user: LogginedUser) {
    this.privateUser = user
    if (user != undefined) {
      this.formGroup.get("firstName").setValue(user.firstName)
      this.formGroup.get("lastName").setValue(user.lastName)
      this.formGroup.get("email").setValue(user.email)
    }
  }

  submitForm() {
    if (this.formGroup.valid) {
      this.loading = true
      const values = this.formGroup.value
      this.apiService.putCurrentUser(values.firstName, values.lastName, values.email, this.privateUser.role.role).subscribe(response => {
        this.loading = false
        this.toastr.success(response.message)
        if (this.formGroup.value.email == this.privateUser.email) {
          this.authService.refreshLoggedUser()
        } else {
          this.authService.clearData();
          this.router.navigate(['/signin']);
        }
      }, error => {
        this.loading = false
        this.toastr.error(error.error.message)
      })

    } else {
      this.toastr.error('Please provide a value for all the required fields')
      this.formGroup.markAllAsTouched()
    }

  }
}
