
<button class="btn btn-outline-danger" (click)="open(content)">
    Withdraw participant
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h1 class="modal-title fs-5">
            <i class="bi bi-exclamation-triangle me-1"></i> Withdrawn Participant
        </h1>
    </div>
    <div class="modal-body" [formGroup]="formGroup">
        <spinner *ngIf="loading"></spinner>
        <p>You are about to withdrawn a participant from the study. The scheduled sessions will be removed and the participant will not be able to access the platform anymore. This action cannot be undone. Are you sure you want to proceed?</p>

        <div class="form-group mb-3">
            <label class="form-label">Requested by (*)</label>
            <select class="form-select" formControlName="requestedBy">
                <option value="">Select one option</option>
                <option value="Participant">Participant</option>
                <option value="Parent/Carer">Parent / Carer</option>
                <option value="Study Manager">Study Nurse</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label class="form-label">Data privacy options (*)</label>
            <select class="form-select" formControlName="dataPrivacyOption">
                <option value="">Select one option</option>
                <option value="Keep data">To allow existing data collected between enrolment and withdrawal to be used in analyses</option>
                <option value="Erase data">To have their data collected between enrolment and withdrawal to be removed/erased and not used in analyses</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label class="form-label">Reason</label>
            <textarea class="form-control" formControlName="reason"></textarea>
        </div>

        <div class="d-flex justify-content-end gap-2 mt-5">
            <button type="button" class="btn btn-danger" (click)="confirm()">Yes, withdrawn</button>
            <button type="button" class="btn btn-outline-secondary" (click)="close()" data-bs-dismiss="modal">
                No, cancel
            </button>
        </div>
    </div>
</ng-template>
