<ng-template #content let-modal>
    <div class="modal-header">
        <h1 class="modal-title fs-5">
            Report an adverse event </h1>
    </div>
    <div class="modal-body" [formGroup]="formGroup">
        <spinner *ngIf="loading"></spinner>

        <div class="form-group mb-3">
            <label class="form-label">Start date (*)</label>
            <input formControlName="startDate" (change)="startDateChanged()" type="date" class="form-control"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label">End date (*)</label>
            <input formControlName="endDate" type="date" class="form-control"/>
            @if(formGroup.get('endDate').invalid && (formGroup.get('endDate').dirty || formGroup.get('endDate').touched )) {
                <div class="text-danger">This field is required and must be greater than start date.</div>
            }
        </div>

        <div class="form-group mb-3">
            <label class="form-label">Severity (*)</label>
            <select class="form-select" formControlName="adverseEventSeverity">
                <option value="">Select one option</option>
                @for (item of severities; track item) {
                    <option value="{{item.id}}">{{ item.name }}</option>
                }
            </select>
        </div>

        <div class="form-group mb-3">
            <label class="form-label">Description</label>
            <textarea formControlName="description" class="form-control"></textarea>
        </div>

        <div class="form-group mb-3">
            <label class="form-label">Is participant withdrawn? (*)</label>
            <select class="form-select" formControlName="isParticipantWithdrawn" (change)="participantWithdrawnChanged()">
                <option value="">Select one option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>

        <div class="form-group mb-3" *ngIf="showWithdrawalReasons">
            <label class="form-label">Reason for the withdrawal</label>
            <textarea formControlName="withdrawalReason" class="form-control"></textarea>
        </div>

        <div class="d-flex justify-content-end gap-2 mt-5">
            @if (!disabled) {
                <button type="button" class="btn btn-danger" (click)="confirm()">Submit</button>
            }
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                Close
            </button>
        </div>
    </div>
</ng-template>