import { AuthService } from '@services/auth.service';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarModule } from '@fullcalendar/angular';
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {ModalDismissReasons, NgbModal, NgbModalModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    SigninComponent,
    VerifyEmailComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CommonModule,
    FullCalendarModule,
    NgbProgressbarModule,
    NgbModalModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent

  title = 'univa-health';

  idleState = "NOT_STARTED";
  countdown?: number = null;
  percentage?: number = null;
  idleTimeout: number = 30

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    events: [
      { title: 'Event 1', date: '2024-05-20' },
      { title: 'Event 2', date: '2024-05-21' },
    ],
  };

  layoudDisabledRoutes = ['/signin', '/forgot-password', '/reset-password'];

  constructor(
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    idle.setIdle(600); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(this.idleTimeout); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      cd.detectChanges();
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.resetIdle()
      this.modalService.dismissAll(ModalDismissReasons.ESC)
      this.headerComponent.logOut()
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds
      this.percentage = 100 - seconds * 100 / this.idleTimeout
    });
  }

  ngOnInit(): void {
    this.resetIdle()
  }

  resetIdle() {
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
  }

  get isUserLogged(): boolean {
    return this.authService.isUserLogged;
  }

  get isLayoutDisabled(): boolean {
    return !this.authService.isUserLogged ||
      this.layoudDisabledRoutes.some((route: string) => this.router.url.includes(route));
  }
}
