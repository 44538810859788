
<button class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#complete-modal">
    Complete study
</button>

<div class="modal fade" id="complete-modal" tabindex="-1" aria-labelledby="complete-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="complete-modal-label">
                    <i class="bi bi-exclamation-triangle me-1"></i> Complete study
                </h1>
            </div>
            <div class="modal-body">
                <spinner *ngIf="loading"></spinner>
                <p>You are about to complete the study for this participant.
                    The scheduled visits will be removed and the participant will not be able to upload data anymore.
                    This action cannot be undone. Are you sure you want to proceed?</p>

                <div class="d-flex justify-content-end gap-2 mt-5">
                    <button type="button" class="btn btn-danger" (click)="confirm()">Yes, complete</button>
                    <button #closeModal type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        No, cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>