<spinner *ngIf="loading"></spinner>

<h5 class="mb-0 pt-4 mt-4">Personal Information</h5>
<hr class="mt-2 mb-4"/>

<div class="modal-body" [formGroup]="formGroup">
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-3">
                <label class="form-label">First Name</label>
                <input formControlName="firstName" type="string" class="form-control"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-3">
                <label class="form-label">Last Name</label>
                <input formControlName="lastName" type="string" class="form-control"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-3">
                <label class="form-label">Email</label>
                <input formControlName="email" type="string" class="form-control"/>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <button type="button" class="btn btn-success" (click)="submitForm()">
            Update
        </button>
    </div>

</div>