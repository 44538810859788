<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/site-list">Sites</a></li>
    <li class="breadcrumb-item active" aria-current="page">List</li>
  </ol>
</nav>

<div class="card">
  <h4 class="card-header">Site List</h4>
  <div class="card-body">
    <ngx-datatable
      #datatable
      class="bootstrap"
      [rows]="rows"
      [columnMode]="'flex'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [limit]="page.size"
      [offset]="page.pageNumber"
      [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
      (page)="setPage($event)"
    >
      <ngx-datatable-column
        prop="name"
        name="Site"
        [sortable]="true"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.name }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="totalSiteMembers"
        name="# of Site Members"
        [sortable]="false"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.totalSiteMembers || '-' }}
        </ng-template>
      </ngx-datatable-column>

        <ngx-datatable-column
                prop="studyShortCode"
                name="Studies"
                [sortable]="true"
                [flexGrow]="1"
        >
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.studyShortCode }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
        prop="participantsRecruited"
        name="# of participants recruited"
        [sortable]="true"
        [flexGrow]="1"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.participantsRecruited || '-' }}
        </ng-template>
      </ngx-datatable-column>

        @if (Permissions.EDIT_SITE | hasPermission) {
        <ngx-datatable-column
          prop="id"
          name="Actions"
          [sortable]="true"
          [flexGrow]="1"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/edit-site', row.id]" class="btn btn-outline-success px-3">
              View details
            </a>
          </ng-template>
        </ngx-datatable-column>
      }
    </ngx-datatable>
  </div>
</div>

<spinner *ngIf="loading"></spinner>
