<div class="auth-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="card h-auto">
          <div class="card-body">
            <div class="text-center mb-4">
              <a
                ><img class="logo-auth" src="assets/images/logo.svg" alt="logo"
              /></a>
            </div>
            <h4 class="text-center mb-4">Enter your email to reset password</h4>
            <form  [formGroup]="passForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label class="form-label" for="username">Email</label>
                <input
                  type="text"
                  formControlName="email"
                  class="form-control"
                  placeholder="Enter your email here"
                />
                <p class="text-danger"
                  [class.visible]="passForm.get('email').invalid
                    && passForm.get('email').touched"
                  >Email is {{ passForm.value.email ? 'invalid' : 'required'}}.</p>

                @if(emailSent){
                  <p class="success-message">
                    An email will be sent to you with instructions on how to restore your password.</p>
                }
              </div>

              @if(!emailSent){
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-success"
                    [disabled]="passForm.invalid"
                  >
                    Submit
                  </button>
                </div>
              }
                <div class="text-center">
                  <a [routerLink]="['/signin']" class="btn btn-link py-3 btn-link-small">
                    Back to sign-in
                  </a>
                </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<spinner *ngIf="loading"></spinner>
