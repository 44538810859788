<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/study-list">Studies</a></li>
      <li class="breadcrumb-item active" aria-current="page">Edit Study</li>
    </ol>
  </nav>

  <div class="card">
    <h4 class="card-header">Edit Study</h4>
    <div class="card-body px-2 py-4">
      <mat-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="firstFormGroup" label="Study Information">
          <!-- <form [formGroup]="firstFormGroup"> -->
          <app-study-information></app-study-information>

          <div class="text-end mt-4">
            <button mat-button  class="btn btn-success" (click)="nextStep1()">
              Next <i class="bi bi-arrow-right ms-1"></i>
            </button>
          </div>
          <!-- </form> -->
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="Screening & Demographics">
          <form [formGroup]="secondFormGroup">
            <app-screening-questionnaire></app-screening-questionnaire>
            <div class="d-flex justify-content-between mt-5">
              <button mat-button matStepperPrevious class="btn btn-secondary">
                <i class="bi bi-arrow-left me-1"></i> Back
              </button>
              <button mat-button matStepperNext (click)="screeingInfo()" class="btn btn-success" >
                Next <i class="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
          </form>

        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" label="Informed Consent Form">
          <form [formGroup]="thirdFormGroup">
            <app-informed-consent-form></app-informed-consent-form>
            <div class="d-flex justify-content-between mt-5">
              <button mat-button matStepperPrevious class="btn btn-secondary">
                <i class="bi bi-arrow-left me-1"></i> Back
              </button>
              <button mat-button (click)="consentForm()" class="btn btn-success">
                Next <i class="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="fourFormGroup"   label="Data Collection">

          <form [formGroup]="fourFormGroup">
            <app-data-collection></app-data-collection>
            <div class="d-flex justify-content-between mt-4">
              <button mat-button matStepperPrevious class="btn btn-secondary">
                <i class="bi bi-arrow-left me-1"></i> Back
              </button>
              <button mat-button  class="btn btn-success" (click)="screeningQuestionnairNext()">
                Next <i class="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="fiveFormGroup">
          <ng-template matStepLabel>Confirmation</ng-template>


          <section>

            <div class="d-flex justify-content-between align-items-center mt-2">
              <h5 class="mb-0">Confirmation</h5>
              <a href="#" tooltipClass="custom-tooltip" ngbTooltip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy">
                <i class="bi bi-info-circle"></i>
              </a>
            </div>

            <hr class="mt-2 mb-4" />

            <div class="row mb-3">
              <div class="col-md-6">
                <span class="fw-medium">Study Name:</span>
                {{StudyInformationComponent?.studyInformationForm?.value?.name}}
              </div>
              <div class="col-md-6">
                <span class="fw-medium">Study short code:</span>
                {{StudyInformationComponent?.studyInformationForm?.value?.shortCode}}
              </div>
            </div>

            <h6>Study description:</h6>
            <p>
              {{StudyInformationComponent?.studyInformationForm?.value?.description}}
            </p>

            <div class="row mt-4">
              <div class="col-md-6">
                <p><span class="fw-medium">Start date:</span>
                  {{StudyInformationComponent?.studyInformationForm?.value?.startDate}}</p>
                <p><span class="fw-medium">Planned number of participant:</span>
                  {{StudyInformationComponent?.studyInformationForm?.value?.plannedNumberOfParticipants}}</p>
              </div>
              <div class="col-md-6">
                <p><span class="fw-medium">Planned end date:</span>
                  {{StudyInformationComponent?.studyInformationForm?.value?.plannedEndDate}}</p>
                <p>
                  <span class="fw-medium">Study duration per participant (weeks):
                    {{StudyInformationComponent?.studyInformationForm?.value?.durationInWeeksPerParticipant}}</span>
                  No duration defined
                </p>
              </div>
            </div>

            <h5 class="mt-5">Data Collection and Schedule Settings</h5>
            <hr class="mt-2 mb-3" />

            <div class="row">
              <div class="col-md-4">
                <p class="text-success fw-medium mb-1">On-site measurements</p>
                <ul>
                  @for (item of onsiteLists; track $index) {
                  <li>
                    <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                    {{ item?.name }}
                  </li>
                  }

                </ul>

                <p class="text-success fw-medium mb-1 mt-4 pt-2">
                  Video diary topics battery
                </p>
                @for (item of DataCollectionComponent?.videodiarytopicsObject; track $index) {
                <ul>
                  <li> {{item?.topicId}}, duration X {{item?.durationSecs}}</li>
                </ul>
              }
              </div>

              <div class="col-md-4">
                <p class="text-success fw-medium mb-1 mt-4 mt-md-0">
                  In-app digital measurements
                </p>
                <ul>
                  @for (item of inAppLists; track $index) {
                  <li>
                    <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                    {{ item?.name }}
                  </li>
                  }
                  <!-- <li>
                    <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                    Video diary
                  </li>
                  <li>
                    <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                    Fingertip analysis
                  </li> -->
                </ul>
              </div>


            </div>

            <h5 class="mt-5">Informed Consent Form for participant</h5>
            <hr class="mt-2 mb-3" />

            <p>
            {{  InformedConsentFormComponent?.icfParticipant}}
            </p>

            <p class="fw-medium mb-2 mt-4">Additional clauses</p>
            @for (item of InformedConsentFormComponent?.participantList; track $index) {
            <p class="mb-1">{{ item?.description }}</p>
            }

            <h5 class="mt-5">Informed Consent Form for parent/carer</h5>
            <hr class="mt-2 mb-3" />

            <p>
              {{  InformedConsentFormComponent?.icfParticipant}}
            </p>

            <p class="fw-medium mb-2 mt-4">Additional clauses</p>
            @for (item of InformedConsentFormComponent?.carerList; track $index) {
              <p class="mb-1">{{ item?.description }}</p>
              }


            <h5 class="mt-5">Study Manager Control Check</h5>
            <hr class="mt-2 mb-3" />

            <p>
              {{  InformedConsentFormComponent?.icfStudyManager}}
            </p>

            <h5 class="mt-5">Screening Form</h5>
            <hr class="mt-2 mb-3" />

            <p>
              <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
              Monitoring Study Screening Form
            </p>




          </section>

          <div class="d-flex justify-content-between mt-5">
            <button mat-button matStepperPrevious class="btn btn-secondary">
              <i class="bi bi-arrow-left me-1"></i> Back
            </button>
            <button mat-button class="btn btn-success" (click)="onSubmit()">Update</button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
