<div class="auth-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="card h-auto">
          <div class="card-body">
            <div class="text-center mb-4">
              <a
                ><img class="logo-auth" src="assets/images/logo.svg" alt="logo"
              /></a>
            </div>
            <h4 class="text-center mb-4">Set a new password</h4>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-3">
                <label class="form-label">New password</label>
                <input
                  type="password"
                  formControlName="password"
                  class="form-control"
                  placeholder="Enter new password"
                />
                <!-- @if(resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched){
                  <div class="text-danger">This field is required.</div>
                } -->
                @if(resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched && resetPasswordForm.get('password').errors['required']){
                  <div class="text-danger">This field is required.</div>
                }
                @if(resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched && resetPasswordForm.get('password').errors['pattern']){
                  <div class="text-danger">Password must be at least 8 characters, containing 1 uppercase, 1 digit and 1 special character</div>
                }
              </div>
              <div class="form-group mb-3 mb-sm-4">
                <label class="form-label">Repeat password</label>

                <input
                  type="password"
                  formControlName="passwordRepeat"
                  class="form-control"
                  value="Password"
                  placeholder="Repeat new password"
                />
                @if(resetPasswordForm.get('passwordRepeat').invalid && resetPasswordForm.get('passwordRepeat').touched){
                  <div class="text-danger">This field is required.</div>
                }
                @if(resetPasswordForm.get('passwordRepeat').touched && resetPasswordForm.errors && resetPasswordForm.errors['passwordMismatch']){
                  <div class="text-danger">Passwords do not match.</div>
                }
              </div>

              @if(resetSuccess){
                <p class="success-message">
                  Your password has been restored. You can now use it to sign in.
                </p>
              }
              @if(!resetSuccess){
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-success"
                    [disabled]="resetPasswordForm.invalid"
                  >
                    Submit
                  </button>

                </div>
              }

              <div class="text-center">
                <a [routerLink]="['/signin']" class="btn btn-link py-3 btn-link-small">
                  Back to sign-in
                </a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<spinner *ngIf="loading"></spinner>
