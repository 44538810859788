<spinner *ngIf="loading"></spinner>

<h5 class="mb-0 pt-4 mt-4">Change Password</h5>
<hr class="mt-2 mb-4"/>

<div class="modal-body" [formGroup]="formGroup">
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-3">
                <label class="form-label">Current Password</label>
                <input formControlName="currentPassword" type="password" class="form-control"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-3">
                <label class="form-label">New Password</label>
                <input formControlName="newPassword" type="password" class="form-control" (change)="changedPassword()"/>
            </div>
            <div class="form-group mb-3">
                <label class="form-label">Repeat New Password</label>
                <input formControlName="repeatPassword" type="password" class="form-control"/>
            </div>

        </div>
        <div class="col-6">
            <ul class="password-strength-check">
                <li [ngClass]="{'check-success': formGroup.value.newPassword.match(regexDigit)}">
                    @if (formGroup.value.newPassword.match(regexDigit)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-x-square"></i>
                    }
                    &nbsp; At least one digit
                </li>
                <li [ngClass]="{'check-success': formGroup.value.newPassword.match(regexUppercase)}">
                    @if (formGroup.value.newPassword.match(regexUppercase)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-x-square"></i>
                    }
                    &nbsp; At least one uppercase letter
                </li>
                <li [ngClass]="{'check-success': formGroup.value.newPassword.match(regexLowercase)}">
                    @if (formGroup.value.newPassword.match(regexLowercase)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-x-square"></i>
                    }
                    &nbsp; At least one lowercase letter
                </li>
                <li [ngClass]="{'check-success': formGroup.value.newPassword.match(regexSpecial)}">
                    @if (formGroup.value.newPassword.match(regexSpecial)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-x-square"></i>
                    }
                    &nbsp; At least one special character
                </li>
                <li [ngClass]="{'check-success': formGroup.value.newPassword.match(regexLength)}">
                    @if (formGroup.value.newPassword.match(regexLength)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-x-square"></i>
                    }
                    &nbsp; At least 8 characters long
                </li>
            </ul>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <button type="button" class="btn btn-success" (click)="submitForm()">
            Update
        </button>
    </div>

</div>