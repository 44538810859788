<div class="auth-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="card h-auto">
          <div class="card-body">
            <div class="text-center mb-4">
              <a href="index.html"
                ><img class="logo-auth" src="assets/images/logo.svg" alt="logo"
              /></a>
            </div>
            <h4 class="text-center mb-4">Sign In</h4>
            <form>
              <div class="form-group mb-4 px-md-4">
                <label class="form-label" for="username"
                  >Enter the code received via email</label
                >

                <div class="d-flex code-input gap-3">
                  <input type="text" class="form-control" placeholder="-" />
                  <input type="text" class="form-control" placeholder="-" />
                  <input type="text" class="form-control" placeholder="-" />
                  <input type="text" class="form-control" placeholder="-" />
                </div>
              </div>

              <div class="text-center">
                <button type="submit" class="btn btn-success mt-2">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
