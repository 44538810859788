<button class="btn btn-sm btn-outline-danger" (click)="open()">
    Remove
</button>

<ng-template #content>
    <div class="modal-header">
        <h1 class="modal-title fs-5">
            <i class="bi bi-exclamation-triangle me-1"></i> Remove reported adverse event </h1>
    </div>
    <div class="modal-body">
        <spinner *ngIf="loading"></spinner>
        <p>You are about to remove an adverse event previously reported. This action cannot be undone. Are you sure you
            want to proceed?</p>

        <div class="d-flex justify-content-end gap-2 mt-5">
            <button type="button" class="btn btn-danger" (click)="confirm()">Yes, remove</button>
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                No, cancel
            </button>
        </div>
    </div>
</ng-template>