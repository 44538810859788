import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatStepperNext } from "@angular/material/stepper";
import { ApiService } from "../../../../api.service";
import { PostParticipantFormResultsResponse } from "../../../../api/api.interfaces";
import { ToastrService } from "ngx-toastr";
import { NgIf } from "@angular/common";
import { FormFieldComponent } from "../form-field/form-field.component";
import { QuestionnaireFormComponent } from "../form/questionnaire-form.component";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { Participant, ParticipantStatus } from '@models/participant.model';
import { ParticipantsService } from '@services/participants.service';

@Component({
  selector: 'screening-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButton,
    MatStepperNext,
    NgIf,
    FormFieldComponent,
    QuestionnaireFormComponent,
    SpinnerComponent
  ],
  templateUrl: './screening-form.component.html',
})
export class ScreeningFormComponent {
  @Input() disabled: boolean = false
  @Output() formResponse = new EventEmitter<PostParticipantFormResultsResponse>();

  @ViewChild(QuestionnaireFormComponent) questionaireForm: QuestionnaireFormComponent;

  participant: Participant;

  isRejected: boolean = false;

  loading: boolean = true;

  constructor(
    private apiService: ApiService,
    private participantsService: ParticipantsService,
    private toastr: ToastrService
  ) {
  }

  refresh(participantId: number) {
    this.participantsService.getById(participantId).subscribe(response => {
      this.participant = response.data
      this.questionaireForm.refresh(this.participant, "screenings")
      if (this.participant != undefined && this.participant.status.status == ParticipantStatus.ScreenedOut) {
        this.questionaireForm.disable()
        this.isRejected = true
      }
      this.loading = false
    })
  }

  submitForm() {
    if (this.questionaireForm.isValid()) {
      this.loading = true
      this.apiService.postParticipantFormResults(this.participant.id, this.questionaireForm.getFormId(), this.questionaireForm.getResult()).subscribe(response => {
        if (response.data.status == "ACCEPTED") {
          this.formResponse.emit(response)
        } else {
          this.isRejected = true
          this.questionaireForm.disable()
          this.questionaireForm.refresh(this.participant, "screenings")
          this.loading = false
        }
      })
    } else {
      this.toastr.error("Please provide a value for all the required fields");
    }
  }
}
