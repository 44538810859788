
<h5 class="mt-2 mb-0">Study Information</h5>
<hr class="mt-2 mb-4" />

<form [formGroup]="formGroup">

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Select Study (*)</label>
          <select class="form-select" formControlName="study">
            <option selected value="">Select one option</option>
              @for (study of studyList; track study) {
                  <option value="{{study.id}}">{{study.name}}</option>
              }
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="form-label">Select Study Site (*)</label>
          <select class="form-select" formControlName="studySite">
            <option selected value="">Select one option</option>
              @for (site of currentSiteList; track site) {
                  <option value="{{site.id}}">{{site.name}}</option>
              }
          </select>
        </div>
      </div>
    </div>

    <div class="text-end mt-4">
        <button class="btn btn-success" (click)="processForm()">
            Next <i class="bi bi-arrow-right ms-1"></i>
        </button>
    </div>
</form>
