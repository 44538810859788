import {ParticipantStatus} from '@services/models/participant.model';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {ApiService} from "../../../api.service";
import {BasicIdName, ListParticipantItem, Page, Site, Study} from "../../../api/api.interfaces";
import {DatePipe, NgIf} from "@angular/common";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {SpinnerComponent} from "@components/spinner/spinner.component";
import {PermissionPipe} from '@components/directives/permission.pipe';
import {Permissions} from '@models/permissions.enum';
import {participantStatusList} from '@models/participant.model';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";

@Component({
    selector: 'participants-table',
    standalone: true,
    imports: [
        RouterOutlet, RouterLink, DatePipe,
        NgxDatatableModule, NgIf, SpinnerComponent, PermissionPipe, ReactiveFormsModule, NgSelectModule, FormsModule],
    templateUrl: './participants-table.component.html',
})
export class ParticipantsTableComponent implements OnInit {
    @Input() studyId?: number
    @Input() columns: Array<string> = ['participantName', 'participantCode', 'studyCode', 'site', 'status', 'nextSession']
    @ViewChild("table") table: any;
    page: Page = {
        size: 10,
        start: 0,
        totalCount: 0
    };
    rows: ListParticipantItem[]
    sortField: string = 'participantCode'
    order: string = 'asc'
    loading: boolean = true;
    Permissions = Permissions;
    Status = ParticipantStatus;
    participantStatusList = participantStatusList;

    filterForm: FormGroup
    studies: Study[]
    sites: Site[]
    statuses: BasicIdName[]

    constructor(
        private apiService: ApiService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.filterForm = this.fb.group({
            study: [null],
            site: [null],
            status: [null],
            lastName: [''],
            participantCode: [''],
        });
        if (this.studyId != undefined) {
            this.filterForm.get("study").setValue([this.studyId])
        }

        this.page = {
            size: 10,
            start: 0,
            totalCount: 0
        }

        this.fetchStudies()
        this.fetchSites()
        this.fetchStatuses()
        this.refreshRows()
    }

    get showStudyField() {
        return this.studyId == undefined
    }

    setPage(pageInfo) {
        this.loading = true
        this.page.start = pageInfo.offset * this.page.size
        this.refreshRows()
    }

    sort(pageInfo) {
        this.loading = true
        this.sortField = pageInfo.sorts[0].prop
        this.order = pageInfo.sorts[0].dir
        this.table.offset = this.page.start
        this.refreshRows()
    }

    fetchStudies() {
        this.apiService.studyListing({}).subscribe(response => {
            this.studies = response.data
        })
    }

    fetchSites() {
        const studyValue = this.filterForm.value.study
        const filter = studyValue != undefined && studyValue.length > 0? {study:studyValue.join(',')} : {}

        this.apiService.siteListing(filter).subscribe(response => {
            this.sites = response.data
        })
        this.filterForm.get("site").reset()
    }

    fetchStatuses() {
        this.apiService.listParticipantStatus().subscribe(response => {
            this.statuses = response.data
        })
    }

    refreshRows() {
        const filter: {
            study?: string,
            site?: string,
            status?: string,
            lastName?: string,
            participantCode?: string
        } = {}
        const value = this.filterForm.value
        if (value.study != undefined && value.study.length > 0) {
            filter.study = value.study.join(',')
        }
        if (value.site != undefined && value.site.length > 0) {
            filter.site = value.site.join(',')
        }
        if (value.status != undefined && value.status.length > 0) {
            filter.status = value.status.join(',')
        }
        if (value.lastName != "" && value.lastName != null) {
            filter.lastName = value.lastName
        }
        if (value.participantCode != "" && value.participantCode != null) {
            filter.participantCode = value.participantCode
        }

        this.apiService.listParticipants(this.page.start, this.sortField, this.order, filter).subscribe(res => {
            if (!res?.data?.length) {
                this.rows = []
                this.page.start = 0
                this.page.totalCount = 0
                this.loading = false;
                return;
            }
            this.rows = res.data
            this.page = res.pagination
            this.loading = false
        });
    }

    reset() {
        this.filterForm.reset()
        if (this.studyId != undefined) {
            this.filterForm.get("study").setValue([this.studyId])
        }
        this.filter()
    }

    filter() {
        this.loading = true
        this.page.start = 0
        this.refreshRows()
    }
}
