
<spinner *ngIf="loading"></spinner>

<form class="d-flex justify-content-between" [formGroup]="filterForm">
    <div class="row g-2">
        <div class="form-group col" *ngIf="showStudyField">
            <label class="form-label">Study</label>
            <ng-select [multiple]="true" placeholder="Select 1+ options" formControlName="study" (change)="fetchSites()">
                @for (item of studies; track $index) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="form-group col">
            <label class="form-label">Site</label>
            <ng-select [multiple]="true" placeholder="Select 1+ options" formControlName="site">
                @for (item of sites; track $index) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="form-group col">
            <label class="form-label">Status</label>
            <ng-select [multiple]="true" placeholder="Select 1+ options" formControlName="status">
                @for (item of statuses; track $index) {
                    <ng-option value="{{ item.status }}">{{item.status}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="form-group col">
            <label class="form-label">Last name</label>
            <input type="text" class="form-control" formControlName="lastName" placeholder="Enter last name" />
        </div>
        <div class="form-group col">
            <label class="form-label">Participant code</label>
            <input type="text" class="form-control" formControlName="participantCode" placeholder="Enter code" />
        </div>
        <div class="col-auto d-flex align-items-end flex-row-reverse pb-1">
            <button type="submit" class="btn btn-success px-3" (click)="filter()">
                <i class="bi bi-search"></i>
            </button>
        </div>
        <div class="col d-flex align-items-end pb-1">
            <a type="reset" class="btn btn-link px-3" (click)="reset()">Clear filters</a>
        </div>
    </div>
</form>

<hr>

<ngx-datatable class="bootstrap" #table
  [rows]="rows"
  [columnMode]="'force'"
  [headerHeight]="50"
  [externalPaging]="true"
  [externalSorting]="true"
  [footerHeight]="30"
  [rowHeight]="'auto'"
  [limit]="page.size"
  [count]="page.totalCount"
  [offset]="offset"
  (page)="setPage($event)"
  [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
  (sort)="sort($event)"
>
  <ngx-datatable-column prop="participantName" name="Participant" [sortable]="true" [flexGrow]="1" *ngIf="columns.includes('participantName')">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.patient ? row?.patient?.user?.lastName : '-' }}
      </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="participantCode" name="Participant code" [sortable]="true" [flexGrow]="1" *ngIf="columns.includes('participantCode')">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.participantCode }}
      </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="studyCode" name="Study short code" [sortable]="true" [flexGrow]="1" *ngIf="columns.includes('studyCode')">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.studySite.study.code }}
      </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="site" name="Study site" [sortable]="true" [flexGrow]="1" *ngIf="columns.includes('site')">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.studySite.site.name }}
      </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="status" name="Status" [sortable]="false" [flexGrow]="1" *ngIf="columns.includes('status')">
      <ng-template let-row="row" ngx-datatable-cell-template>
        @for (statusItem of participantStatusList; track statusItem) {
          <span
            *ngIf="row?.status.status == statusItem.value"
            [class]="statusItem.class"
          >{{statusItem.text}}</span>
        }
      </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="nextSession" name="Next session" [sortable]="false" [flexGrow]="1" *ngIf="columns.includes('nextSession')">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.nextSession == null? '---' : row?.nextSession | date }}
      </ng-template>
  </ngx-datatable-column>

@if (Permissions.EDIT_PARTICIPANT | hasPermission) {
  <ngx-datatable-column prop="actions" name="Actions" [sortable]="false" [flexGrow]="1">
      <ng-template let-row="row" ngx-datatable-cell-template>
          @if (row?.status.status == Status.Active
            || row?.status.status == Status.Completed
            || row?.status.status == Status.Withdrawn) {
              <a class="btn btn-sm btn-outline-success"
                routerLink="/participant-overview/{{ row?.id }}">View details</a>
          }
          @else {
              <a class="btn btn-sm btn-outline-success"
                routerLink="/add-new-participant/{{ row?.id }}">View details</a>
          }
      </ng-template>
  </ngx-datatable-column>
}

</ngx-datatable>
