import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideToastr} from 'ngx-toastr';
import {SampleInterceptor} from './log.interceptor';
import {provideQuillConfig} from "ngx-quill";
import {provideNgIdle} from "@ng-idle/core";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        // provideHttpClient(withInterceptors([authInterceptor,logInterceptor])),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SampleInterceptor,
            multi: true
        },
        provideClientHydration(),
        provideAnimationsAsync(),
        provideNgIdle(),
        provideHttpClient(),
        provideToastr(),
        provideQuillConfig({
            modules: {
                syntax: false,
                // toolbar: [...]
            }
        })
    ],
};
