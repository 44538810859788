import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from "../../../../api.service";
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BasicIdName } from "../../../../api/api.interfaces";
import moment from "moment";
import { NgIf } from "@angular/common";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { Participant, ParticipantStatus, PatientEdDiagnose } from '@models/participant.model';
import { ParticipantsService } from '@services/participants.service';
import {WithdrawnComponent} from "@components/participants/overview/withdrawn/withdrawn.component";
import {NgSelectModule} from "@ng-select/ng-select";

@Component({
  selector: 'personal-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    SpinnerComponent,
    NgSelectModule,
    WithdrawnComponent
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: './personal-info.component.scss'
})
export class PersonalInfoComponent implements OnInit {

  @Output() result = new EventEmitter<{}>();

  loading: boolean = true
  editing: boolean

  participant: Participant;

  edDiagnoses: PatientEdDiagnose[]
  comorbidities: BasicIdName[]
  foodIntakeStatus: BasicIdName[]
  carerRelationship: BasicIdName[]

  patientGroup = this.formBuilder.group({
    lastName: ['', Validators.required],
    birthDate: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    biologicalSex: ['', Validators.required],
    nhsNumber: ['', Validators.maxLength(10)],
    nhsNumber2: [''],
    lackOfCapability: [''],
  }, {
    validators: [
      (control) => {
        if (control.get("nhsNumber").value != control.get("nhsNumber2").value) {
          control.get("nhsNumber2").setErrors({ ...(control.errors || {}), match: "Value does not match" })
        }
        return null
      }
    ]
  });

  carerGroup = this.formBuilder.group({
    firstName: ['', this.carerValidator()],
    lastName: ['', this.carerValidator()],
    email: ['', [this.carerValidator(), Validators.email]],
    birthDate: [''],
    relationship: ['', this.carerValidator()],
  });

  profileGroup = this.formBuilder.group({
    initialWeight: ['', [Validators.required, Validators.pattern("^[0-9\.]*$")]],
    initialHeight: ['', [Validators.required, Validators.pattern("^[0-9\.]*$")]],
    edDiagnosis: ['', Validators.required],
    firstEdDiagnosisYear: ['', Validators.required],
    firstEdDiagnosisMonth: ['', Validators.required],
    comorbidities: [new FormControl<string[]>([]), Validators.required],
    type: ['', Validators.required],
    foodIntakeStatus: ['', Validators.required],
    isTakingMedication: ['', Validators.required],
    medicationNotes: ['', (control: FormControl) => {
      // if ((this.profileGroup?.get("isTakingMedication").value == "1") && (control.value == "" || control.value == null)) {
      //     return {required: true}
      // }
      return null
    }]
  });

  constructor(
    private apiService: ApiService,
    private participantsService: ParticipantsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  refresh(participantId: number, editing: boolean) {
    this.loading = true
    this.editing = editing
    this.apiService.listEdDiagnoses().subscribe(response => {
      this.edDiagnoses = response.data
    })
    this.apiService.listComorbidities().subscribe(response => {
      this.comorbidities = response.data
    })
    this.apiService.listFoodIntakeStatus().subscribe(response => {
      this.foodIntakeStatus = response.data
    })
    this.apiService.listCarerRelationships().subscribe(response => {
      this.carerRelationship = response.data
    })
    this.participantsService.getById(participantId).subscribe(response => {
      this.participant = response.data
      const signaturePending = this.participant.status.status == ParticipantStatus.IcfPending
      const completed = this.participant.status.status == ParticipantStatus.Completed
      const withDrawn = this.participant.status.status == ParticipantStatus.Withdrawn
      if (signaturePending) {
        this.patientGroup.disable()
        this.carerGroup.disable()
        this.profileGroup.disable()
      } else {
        this.patientGroup.enable()
        this.carerGroup.enable()
        if (withDrawn || completed) {
          this.patientGroup.get("lackOfCapability").disable()
          this.profileGroup.disable()
        } else {
          this.profileGroup.enable()
        }
      }
      this.patientGroup.patchValue({
        lastName: this.participant.patient.user.lastName,
        birthDate: this.participant.patient.dateOfBirth,
        email: this.participant.patient.user.email,
        biologicalSex: this.participant.patient.biologicalSex ?? "",
        nhsNumber: this.participant.patient.nhsNumber,
        nhsNumber2: this.participant.patient.nhsNumber,
        lackOfCapability: this.participant.lackOfCapability,
      })
      this.carerGroup.patchValue({
        firstName: this.participant.carer?.user.firstName,
        lastName: this.participant.carer?.user.lastName,
        email: this.participant.carer?.user.email,
        birthDate: this.participant.carer?.dateOfBirth,
        relationship: this.participant.carer?.relationship.id == null ? "" : this.participant.carer?.relationship.id.toString(),
      })
      const firstEdDiagnosis = this.participant.patientEdDiagnoses.map(item => item.firstDiagnosedAt)[0]
      let firstEdDiagnosisDate = null
      if (firstEdDiagnosis != undefined) {
        firstEdDiagnosisDate = new Date()
      }
      const edDiagnosis = this.participant.patientEdDiagnoses.map(item => item.id)[0]
      this.profileGroup.patchValue({
        initialWeight: "" + this.participant.patient.weight,
        initialHeight: "" + this.participant.patient.height,
        edDiagnosis: edDiagnosis == null ? null : edDiagnosis.toString(),
        firstEdDiagnosisYear: firstEdDiagnosisDate ? firstEdDiagnosisDate.getFullYear().toString() : null,
        firstEdDiagnosisMonth: firstEdDiagnosisDate ? (firstEdDiagnosisDate.getMonth() + 1).toString() : null,
        type: this.participant.type ?? "",
        comorbidities: this.participant.patientComorbidities.map(item => "" + item.id),
        foodIntakeStatus: this.participant.foodIntakeStatus.id == null ? null : this.participant.foodIntakeStatus.id.toString(),
        isTakingMedication: this.participant.isTakingMedication ? "1" : "0",
        medicationNotes: this.participant.medicationNotes,
      })
      this.loading = false
    })
  }

  carerValidator() {
    return (control: FormControl) => {
      if ((this.patientGroup.get("lackOfCapability").value || this.participant?.patient.age < 16)
        && (control.value == null || control.value == "")) {
        return { required: true }
      }
      return null
    }
  }

  get signaturePending() {
    if (this.participant == undefined) {
      return true
    }
    return this.participant.status.status == ParticipantStatus.IcfPending
  }

  get completed() {
    if (this.participant == undefined) {
      return true
    }
    return this.participant.status.status == ParticipantStatus.Completed
  }
  getLastYears(): number[] {
    const currentYear = (new Date()).getFullYear()
    let result = []
    for (let i = currentYear; i >= currentYear - 50; i--) {
      result.push(i)
    }
    return result
  }

  calculateAge(): number {
    if (this.patientGroup.get("birthDate") == null || !this.patientGroup.get("birthDate").valid) {
      return -1
    }
    const birthdate = new Date(this.patientGroup.get("birthDate").value)
    const diff = moment().diff(birthdate, 'years')
    return diff >= 0 ? diff : -1
  }

  // get calculatedAge(): string {
  //     const age = this.calculateAge()
  //     return age > 0? "" + age : "---"
  // }

  get isAdolescent() {
    const age = this.calculateAge()
    if (age >= 0 && age < 16) {
      return true
    }
    return !!this.patientGroup.get("lackOfCapability").value;
  }

  get showCarerForm() {
    return !!this.patientGroup.get("lackOfCapability").value || this.participant?.patient.age < 16
  }

  processForm() {
    if (this.patientGroup.valid && this.carerGroup.valid && (this.profileGroup.valid || this.profileGroup.disabled)) {
      this.loading = true
      const payload = {
        study: this.participant.studySite.study.id,
        site: this.participant.studySite.site.id,
        patient: {
          lastName: this.patientGroup.get("lastName").value,
          email: this.patientGroup.get("email").value,
          dateOfBirth: this.patientGroup.get("birthDate").value,
          biologicalSex: this.patientGroup.get("biologicalSex").value,
          nhsNumber: this.patientGroup.get("nhsNumber").value,
          weight: Number(this.profileGroup.get("initialWeight").value),
          height: Number(this.profileGroup.get("initialHeight").value),
        },
        lackOfCapability: !!this.patientGroup.get("lackOfCapability").value,
        isAdolescent: this.showCarerForm,
        comorbidities: this.profileGroup.get("comorbidities").value,
        edDiagnoses: [{
          id: this.profileGroup.get("edDiagnosis").value,
          firstDiagnosedAt: this.profileGroup.get("firstEdDiagnosisYear").value + "-" + (Number(this.profileGroup.get("firstEdDiagnosisMonth").value) <= 9 ? "0" : "") + this.profileGroup.get("firstEdDiagnosisMonth").value + "-01"
        }],
        type: this.profileGroup.get("type").value,
        isTakingMedication: this.profileGroup.get("isTakingMedication").value == "1",
        medicationNotes: this.profileGroup.get("medicationNotes").value,
        foodIntakeStatus: Number(this.profileGroup.get("foodIntakeStatus").value),
      }
      if (this.showCarerForm) {
        payload['carer'] = {
          firstName: this.carerGroup.get("firstName").value,
          lastName: this.carerGroup.get("lastName").value,
          email: this.carerGroup.get("email").value,
          dateOfBirth: this.carerGroup.get("birthDate").value,
          relationship: Number(this.carerGroup.get("relationship").value),
        };
      }
      this.apiService.putParticipant(this.participant.id, payload).subscribe(response => {
        this.toastr.success(response.message)
        this.loading = false
        this.result.emit({});
      })
    } else {
      this.patientGroup.markAllAsTouched()
      this.carerGroup.markAllAsTouched()
      this.profileGroup.markAllAsTouched()
      this.toastr.error('Please provide a value for all the required fields');
    }
  }
}
