import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ApiService} from "../../../../api.service";
import {SpinnerComponent} from "../../../spinner/spinner.component";
import {NgIf} from "@angular/common";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'delete-adverse-event',
    standalone: true,
    imports: [
        SpinnerComponent,
        NgIf
    ],
    templateUrl: './delete-adverse-event.component.html',
})
export class CompleteComponent {
    @Input() participantId: number
    @Input() adverseEventId: number
    @Output() result = new EventEmitter<{}>();

    @ViewChild('content') content: ElementRef
    modalRef: NgbModalRef

    loading: boolean = false

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private toastr: ToastrService
    ) {
    }

    open() {
        this.modalRef = this.modalService.open(this.content, {centered: true});
    }

    close() {
        this.modalRef.close()
    }

    confirm() {
        this.apiService.deleteAdverseEvent(this.participantId, this.adverseEventId).subscribe(response => {
            this.loading = false
            this.toastr.success(response.message)
            this.close()
            this.result.emit({});
        })
    }
}
