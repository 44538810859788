<div
  class="sidebar offcanvas offcanvas-start"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrolling"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="text-center d-none d-lg-block pb-4">
    <a routerLink="/"
      ><img class="logo-auth" src="assets/images/logo.svg" alt="logo"
    /></a>
  </div>

  <button
    type="button"
    class="btn-close d-lg-none"
    data-bs-dismiss="offcanvas"
    aria-label="Close"
  ></button>

  <div class="accordion" id="accordionExample"
      [permission]="Permissions.LIST_STUDIES">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
         routerLink="/study-list" routerLinkActive="active"
        >
          <i class="bi bi-house"></i> Studies
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapsed"
        data-bs-parent="#accordionExample"
        [permission]="Permissions.ADD_STUDY"
      >
        <div class="accordion-body">
          <ul>
            <li>
              <a routerLink="/add-new-study" routerLinkActive="active"
                >Add New Study</a
              >
            </li>

            <li>
              <a routerLink="/study-list" routerLinkActive="active"
                >Study List</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="accordion-item"
      [permission]="Permissions.LIST_SITES">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
          routerLink="/site-list" routerLinkActive="active"
        >
          <i class="bi bi-globe"></i> Sites
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
        [permission]="Permissions.ADD_SITE"
      >
        <div class="accordion-body">
          <ul>
            <li>
              <a routerLink="/add-new-site" routerLinkActive="active"
                >Add New Site</a
              >
            </li>

            <li>
              <a routerLink="/site-list" routerLinkActive="active">Site List</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="accordion-item"
      [permission]="Permissions.LIST_PARTICIPANTS">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
          aria-expanded="false"
          aria-controls="collapseFive"
          routerLink="/participant-list" routerLinkActive="active"
        >
          <i class="bi bi-person"></i> Participants
        </button>
      </h2>
      <div
        id="collapseFive"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
        [permission]="Permissions.REGISTER_PARTICIPANT"
      >
        <div class="accordion-body">
          <ul>
            <li>
              <a routerLink="/add-new-participant" routerLinkActive="active"
                >Add New Participant</a
              >
            </li>
            <li><a routerLink="/participant-list" routerLinkActive="active">Participant List</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="accordion-item"
      [permission]="Permissions.LIST_SESSIONS">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
          aria-expanded="false"
          aria-controls="collapseSix"
          routerLink="/sessions" routerLinkActive="active"
        >
          <i class="bi bi-calendar"></i> Sessions
        </button>
      </h2>
      <div
        id="collapseSix"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      ></div>
    </div>
  </div>
</div>
