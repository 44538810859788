import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, FormsModule, ReactiveFormsModule, HttpClientModule, SpinnerComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  errorMessage!: string;
  resetSuccess = false;
  loading = false;

  passwordPatternValidator = Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeParams();
  }

  initForm() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, this.passwordPatternValidator]],
      passwordRepeat: ['', [Validators.required]],
      token: ['', [Validators.required]]
    }, { validators: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: FormGroup): ValidationErrors | null {
    const password = form.get('password');
    const confirmPassword = form.get('passwordRepeat');

    if (!password || !confirmPassword) {
      return null; // If the form controls don't exist yet
    }

    return password.value === confirmPassword.value ? null : { passwordMismatch: true };
  };

  subscribeParams() {
    this.route.params.subscribe((params: { token: string }) => {
      if (params?.token) {
        this.resetPasswordForm.patchValue({ token: params.token });
      }
    });
  }

  onSubmit() {
    if (!this.resetPasswordForm.valid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    const { password, token } = this.resetPasswordForm.value;
    this.authService.resetPassword(token, password).subscribe(
      (res: any) => {
        this.toastr.success(res.message, ' ');
        this.resetSuccess = true;
        this.loading = false;
      },
      error => {
        this.errorMessage = error.error.message;
        this.toastr.error(error.error.message, ' '); // Assuming API returns error message
        this.loading = false;
      }
    );
  }

}
