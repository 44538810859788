import {AfterViewChecked, ChangeDetectorRef, Component, inject, ViewChild} from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators, } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { StudyInformationComponent } from "../../components/studies/study-information/study-information.component";
import { DataCollectionComponent } from "../../components/studies/data-collection/data-collection.component";
import {
  InformedConsentFormComponent
} from "../../components/studies/informed-consent-form/informed-consent-form.component";
import { ConfirmationComponent } from "../../components/studies/confirmation/confirmation.component";
import {
  ScreeningQuestionnaireComponent
} from "../../components/studies/screening-questionnaire/screening-questionnaire.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ApiService } from "../../api.service";
import { ToastrService } from "ngx-toastr";
import { Router, RouterLink } from "@angular/router";
import {DatePipe, NgIf} from "@angular/common";

@Component({
  selector: "app-add-new-study",
  standalone: true,
  imports: [
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    StudyInformationComponent,
    DataCollectionComponent,
    InformedConsentFormComponent,
    ConfirmationComponent,
    RouterLink,
    ScreeningQuestionnaireComponent,
    HttpClientModule,
    DatePipe,
    NgIf
  ],
  providers: [ApiService],
  templateUrl: "./add-new-study.component.html",
  styleUrl: "./add-new-study.component.scss",
})
export class AddNewStudyComponent implements AfterViewChecked {
  http = inject(HttpClient);

  @ViewChild(StudyInformationComponent)
  public StudyInformationComponent!: StudyInformationComponent;

  @ViewChild(DataCollectionComponent)
  public DataCollectionComponent!: DataCollectionComponent;

  @ViewChild(InformedConsentFormComponent)
  public InformedConsentFormComponent!: InformedConsentFormComponent;

  @ViewChild(ScreeningQuestionnaireComponent)
  public ScreeningQuestionnaireComponent!: ScreeningQuestionnaireComponent;


  firstFormGroup = this._formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });

  fourFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });

  fiveFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });

  isLinear = true;
  inAppLists: any[] = [];
  onsiteLists: any[] = [];

  @ViewChild('stepper') stepper: MatStepper;

  constructor(public _formBuilder: FormBuilder,
    public router: Router,
    public api: ApiService,
    public toastr: ToastrService,
    private cdRef : ChangeDetectorRef
  ) {
  }

  nextStep1() {
    if (this.StudyInformationComponent.studyInformationForm.invalid) {
      this.StudyInformationComponent.studyInformationForm.markAllAsTouched()
      this.StudyInformationComponent.errorMessage = true;
      this.toastr.error('Please provide a value for all the required fields');
    } else {
      this.stepper.steps.get(0).completed = true
      this.stepper.next();
    }
  }

  ngAfterViewChecked() {
      this.cdRef.detectChanges();
  }

  get componentLoaded() {
    return this.StudyInformationComponent != undefined
  }

  screeningNext() {
    if (this.ScreeningQuestionnaireComponent.screenings == undefined
      || this.ScreeningQuestionnaireComponent.demographics == undefined
      || this.ScreeningQuestionnaireComponent.screenings == ""
      || this.ScreeningQuestionnaireComponent.demographics == ""
    ) {
      this.toastr.error('Please provide a value for all the required fields');
    } else {
      this.stepper.steps.get(1).completed = true
      this.stepper.next();
    }
  }

  consentForm() {
    if (!this.InformedConsentFormComponent.formGroup.valid) {
      this.InformedConsentFormComponent.errorMessage = true;
      this.InformedConsentFormComponent.formGroup.markAllAsTouched()
      this.toastr.error('Please provide a value for all the required fields');
    } else {
      this.stepper.steps.get(2).completed = true
      this.stepper.next();
    }
  }

  screeningQuestionnairNext() {
    this.inAppLists = this.DataCollectionComponent?.inAppLists.filter(e => e.checkbox == true);
    this.onsiteLists = this.DataCollectionComponent?.onsiteLists.filter(e => e.checkbox == true);
    if (
      this.inAppLists.length == 0 ||
      this.onsiteLists.length == 0 ||
      this.DataCollectionComponent.selectedMeasurement == ""
    ) {
      this.toastr.error('Please provide a value for all the required fields');
    } else {
      if (!this.DataCollectionComponent.checkVideoDiaryObject()) {
        this.toastr.error('The list of Video diary topics is empty or contains repeated values.');
      } else {
        this.stepper.steps.get(3).completed = true
        this.stepper.next();
      }
    }
  }

  screeningPost(studyId: number) {
    if (this.ScreeningQuestionnaireComponent.screenings && this.ScreeningQuestionnaireComponent.demographics) {
      this.api.postForms({
        "forms": [
          {
            "id": [Number(this.ScreeningQuestionnaireComponent.screenings)],
            "type": "screenings"
          },
          {
            "id": [Number(this.ScreeningQuestionnaireComponent.demographics)],
            "type": "demographics"
          }
        ]
      }, studyId).subscribe(res => {
        if (res && res.data) {

        } else {
          this.toastr.error(res.error, ' ');
        }
      }, err => {
        this.toastr.error(err.error.error, ' ');
      });
    } else {
      this.toastr.error("Please try again");
    }
  }

  measurementPost(studyId: number) {
    this.api.postForms({
      "forms": [
        {
          "id": [Number(this.DataCollectionComponent.selectedMeasurement)],
          "type": "measurements"
        },
      ]
    }, studyId).subscribe(res => {
      if (res && res.data) {

      } else {
        this.toastr.error(res.error, ' ');
      }
    }, err => {
      this.toastr.error(err.error.error, ' ');
    });

  }

  onSubmit() {
    this.api.postStudy({
      "name": this.StudyInformationComponent.studyInformationForm.value.name,
      "description": this.StudyInformationComponent.studyInformationForm.value.description,
      "shortCode": this.StudyInformationComponent.studyInformationForm.value.shortCode,
      "startDate": this.StudyInformationComponent.studyInformationForm.value.startDate,
      "plannedEndDate": this.StudyInformationComponent.studyInformationForm.value.plannedEndDate,
      "plannedNumberOfParticipants": Number(this.StudyInformationComponent.studyInformationForm.value.plannedNumberOfParticipants),
      "durationInWeeksPerParticipant": this.StudyInformationComponent.studyInformationForm.value.durationInWeekPerParticipant == null ? null : Number(this.StudyInformationComponent.studyInformationForm.value.durationInWeekPerParticipant),
      "icfParticipant": this.InformedConsentFormComponent.formGroup.value.icfParticipant,
      "icfParticipantAdolescent": this.InformedConsentFormComponent.formGroup.value.icfParticipantAdolescent,
      "icfCarer": this.InformedConsentFormComponent.formGroup.value.icfCarer,
      "icfStudyManager": this.InformedConsentFormComponent.formGroup.value.icfStudyManager,
      "sites": this.StudyInformationComponent.studyInformationForm.value.sites ?? [],
      "studyMeasurements": [
        ...this.DataCollectionComponent?.inAppLists.map(e => {
          return e = {
            id: e.id,
            active: e.checkbox
          }
        }),
      ],
      "studyVideoDiaryTopics": this.DataCollectionComponent.videoDiaryDisabled ? [
        ...this.DataCollectionComponent.videodiarytopicsObject.map(e => {
          return e = {
            "id": Number(e.topicId.split('-')[0]),
            "duration": Number(e.durationSecs)
          }
        }),
      ] : [],
      "studySurveys": [
        ...this.DataCollectionComponent?.onsiteLists.map(e => {
          return e = {
            id: e.id,
            active: e.checkbox
          }
        })
      ],
      "studyWearables": [
        {
          "id": 2,
          "active": false
        }
      ],
      "icfClauses": [
        ...this.InformedConsentFormComponent?.participantList.map(item => ({
          type: "Participant",
          description: item
        })),
        ...this.InformedConsentFormComponent?.participantAdolescentList.map(item => ({
          type: "Participant Adolescent",
          description: item
        })),
        ...this.InformedConsentFormComponent?.carerList.map(item => ({ type: "Carer", description: item })),
        ...this.InformedConsentFormComponent?.studyManagerList.map(item => ({
          type: "Study Manager",
          description: item
        })),
      ]
    }).subscribe(res => {
      if (res && res.data) {
        this.screeningPost(res.data.id)
        this.measurementPost(res.data.id)
        this.router.navigate(['/study-list']);
        this.toastr.success(res?.message, ' ');
      } else {
        this.toastr.error(res.error, ' ');
      }
    }, err => {
      this.toastr.error(err.error.error, ' ');
    });
  }
}
