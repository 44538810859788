<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">List of Available Pre-built Forms</h5>
</div>
<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-6 col-lg-4">
    <div class="form-group mb-3">
      <label class="form-label">Select Screening Form:</label>
      <select class="form-select" name="screenings" [(ngModel)]="screenings">
        <option value="">Select one option</option>
        @for (item of screeningLists; track $index) {
          <option [value]="item.id">{{item.name}}</option>
        }
      </select>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-6 col-lg-4">
    <div class="form-group mb-3">
      <label class="form-label">Select Demographics Form:</label>
      <select class="form-select" name="demographics" [(ngModel)]="demographics">
        <option value="">Select one option</option>
        @for (item of demographicsLists; track $index) {
          <option [value]="item.id">{{item.name}}</option>
        }
       
      </select>
    </div>
  </div>
</div>

<!-- <p class="mb-2">Additional clauses</p>

<div class="row">
  <div class="col-md-10">
    <div class="form-group mb-3">
      <textarea
        class="form-control"
        rows="3"
        placeholder="Add here your additional clause"
      ></textarea>
    </div>
  </div>
  <div class="col-md-2">
    <p class="text-info">
      <span class="bi bi-plus-circle me-1"></span> Add clause
    </p>
  </div>
</div>

<h5 class="mt-5 mb-0">Study Manager Control Check</h5>
<hr class="mt-2 mb-4" />

<div class="form-group mb-3">
  <textarea
    class="form-control"
    rows="3"
    placeholder="Please click here"
  ></textarea>
</div> -->
