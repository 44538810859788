import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormFieldComponent } from "../form-field/form-field.component";
import { ApiService } from "../../../../api.service";
import { ScreeningFormComponent } from "../screening-form/screening-form.component";
import { DemographicsComponent } from "../demographics/demographics.component";
import { QuestionnaireFormComponent } from "../form/questionnaire-form.component";
import { DatePipe, NgIf } from "@angular/common";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ParticipantsService } from '@services/participants.service';
import { Participant } from '@models/participant.model';
import {SpinnerComponent} from "@components/spinner/spinner.component";

@Component({
  selector: 'confirmation',
  standalone: true,
  imports: [
    FormFieldComponent,
    ScreeningFormComponent,
    DemographicsComponent,
    QuestionnaireFormComponent,
    DatePipe,
    NgIf,
    SpinnerComponent
  ],
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent {
  @Output() prevClicked = new EventEmitter<{}>
  @ViewChild('screeningForm') screeningForm: QuestionnaireFormComponent;
  @ViewChild('demographicsForm') demographicsForm: QuestionnaireFormComponent;

  participant: Participant;
  loading: boolean = false

  constructor(
    private apiService: ApiService,
    private router: Router,
    private participantsService: ParticipantsService,
    private toastr: ToastrService
  ) {
  }

  refresh(participantId: number): void {
    this.participantsService.getById(participantId).subscribe(response => {
      this.participant = response.data
      this.queryParticipantResumeInformation()
    })
  }

  prev() {
    this.prevClicked.emit()
  }

  private queryParticipantResumeInformation() {
    this.screeningForm.refresh(this.participant, "screenings")
    this.demographicsForm.refresh(this.participant, "demographics")
  }

  processForm() {
    this.loading = true
    this.apiService.activateParticipant(this.participant.id).subscribe(response => {
      this.loading = false
      this.toastr.success(response.message)
      this.router.navigateByUrl('/participant-list')
    })
  }
}
