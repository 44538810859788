import {Component, Input} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NgIf} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SpinnerComponent} from "@components/spinner/spinner.component";
import {Site, Study} from "../../../api/api.interfaces";
import {NgApexchartsModule} from "ng-apexcharts";
import {StudiesService} from "@services/studies.service";
import {ApiService} from "../../../api.service";


@Component({
    selector: 'study-dashboard',
    standalone: true,
    imports: [
        NgIf,
        NgSelectModule,
        ReactiveFormsModule,
        SpinnerComponent,
        NgApexchartsModule
    ],
    templateUrl: './study-dashboard.component.html'
})
export class StudyDashboardComponent {
    @Input() studyId: number;
    studies: any[] = [];

    loading: boolean = false;

    filterForm: FormGroup
    sites: Site[]
    teamMembers: any[]

    chartOptions: any;
    weeks: { key: string, value: number }[] = []
    sessions: {
        completed: number,
        partlyCompleted: number,
        scheduled: number,
    };

    constructor(
        private toastr: ToastrService,
        private fb: FormBuilder,
        private studiesService: StudiesService,
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.filterForm = this.fb.group({
            study: [null],
            site: [null],
            teamMember: [null]
        });
        this.filterForm.get("study").disable()
        this.fetchSites()
        this.fetchMembers()
        this.refreshStatistics()
    }

    @Input() set study(study: any) {
        if (study.data != undefined) {
            this.studies.push({id: study.data.id.toString(), name: study.data.shortCode});
            this.filterForm.get("study").setValue(this.studyId.toString())
        }
    }

    fetchSites() {
        const filter = {study: this.studyId, size: 9999}
        this.apiService.siteListing(filter).subscribe(response => {
            this.sites = response.data
        })
    }

    fetchMembers() {
        const filter = this.filterForm.get("site").value?.join(",") ?? ""
        if (filter == "") {
            this.filterForm.get("teamMember").reset()
            this.filterForm.get("teamMember").disable()
        } else {
            this.filterForm.get("teamMember").enable()
            this.apiService.listSiteTeamMembers(filter).subscribe(response => {
                this.teamMembers = response.data
            })
        }
    }

    refreshStatistics() {
        this.loading = true;
        this.studiesService.getStatistics(
            this.studyId, this.filterForm.get("site").value?.join(",") ?? "",
            this.filterForm.get("teamMember").value?.join(",") ?? ""
        ).subscribe({
            next: data => {
                this.weeks = Object.keys(data.data.weeks).map(item => {
                    return {key: item, value: data.data.weeks[item]}
                })
                this.sessions = data.data.sessions

                this.chartOptions = {
                    series: [
                        data.data.statuses["Draft"],
                        data.data.statuses["ICF pending"],
                        data.data.statuses["Screened out"],
                        data.data.statuses["Active"] + data.data.statuses["ICF signed"],
                        data.data.statuses["Completed"],
                        data.data.statuses["Withdrawn"]
                    ],
                    chart: {
                        width: 450,
                        type: "pie"
                    },
                    labels: ["Approached", "Screened", "Screened out", "Enrolled", "Discharged", "Withdrawn"],
                    colors: ['#B19CD7', '#ffb366', '#b3b3b3', '#019a00', '#66b2ff', '#cc0100'],
                    dataLabels: {
                        formatter: function (val, opts) {
                            return opts.w.globals.labels[opts.seriesIndex] + ":  " + opts.w.globals.series[opts.seriesIndex]
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    width: 350
                                },
                            }
                        },
                        {
                            breakpoint: 768,
                            options: {
                                chart: {
                                    width: 450
                                },
                                legend: {
                                    position: "bottom"
                                },
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    width: "100%"
                                },
                                legend: {
                                    position: "bottom"
                                },
                            }
                        }
                    ]
                };
                this.loading = false;
            },
            error: error => {
                this.toastr.error(error.error.error);
                this.loading = false;
            }
        })
    }

    reset() {
        this.filterForm.get("site").reset()
        this.fetchMembers()
        this.refreshStatistics()
    }

    filter() {
        this.refreshStatistics();
    }
}
