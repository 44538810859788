import { Permissions } from '@models/permissions.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PersonalInfoComponent } from '../../components/participants/add-new/personal-info/personal-info.component';
import { IcfsComponent } from '../../components/participants/overview/icfs/icfs.component';
import { StudyOverviewComponent } from '../../components/participants/overview/study-overview/study-overview.component';
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgIf } from "@angular/common";
import {
  ParticipantSessionsComponent
} from "../../components/participants/overview/participant-sessions/participant-sessions.component";
import { QuestionnaireFormComponent } from "../../components/participants/add-new/form/questionnaire-form.component";
import { AdverseEventsComponent } from "../../components/participants/overview/adverse-events/adverse-events.component";
import {
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkButton,
  NgbNavOutlet
} from "@ng-bootstrap/ng-bootstrap";
import { PermissionDirective } from '@components/directives/permission.directive';
import { Participant } from '@models/participant.model';
import { ParticipantsService } from '@services/participants.service';

@Component({
  selector: 'app-participant-overview',
  standalone: true,
  imports: [
    PersonalInfoComponent,
    IcfsComponent,
    StudyOverviewComponent,
    NgIf,
    ParticipantSessionsComponent,
    QuestionnaireFormComponent,
    AdverseEventsComponent,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    RouterLink,
    NgbNavContent,
    NgbNavLinkButton,
    NgbNavOutlet,
    PermissionDirective
  ],
  templateUrl: './participant-overview.component.html',
})
export class ParticipantOverviewComponent implements OnInit {
  @ViewChild(StudyOverviewComponent) studyOverview: StudyOverviewComponent;
  @ViewChild(PersonalInfoComponent) personalInfo: PersonalInfoComponent;
  @ViewChild(ParticipantSessionsComponent) participantSessions: ParticipantSessionsComponent;
  @ViewChild(AdverseEventsComponent) adverseEvents: AdverseEventsComponent;
  @ViewChild('screeningForm') screeningForm: QuestionnaireFormComponent;
  @ViewChild('demographicsForm') demographicsForm: QuestionnaireFormComponent;

  loading: boolean = true
  participantId: number
  participant: Participant
  loadedTabs: Map<string, boolean> = new Map([
    ["study-tab", true],
    ["info-tab", false],
    ["sessions-tab", false],
    ["adverse-tab", false],
    ["question-tab", false],
  ]);

  Permissions = Permissions;

  constructor(
    private participantsService: ParticipantsService,
    private activeRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.participantId = this.activeRoute.snapshot.params['id']
    this.participantsService.getById(this.participantId).subscribe(response => {
      this.loading = false
      this.participant = response.data
      this.studyOverview.refresh(this.participant)
    })
  }

  tabChanged(selectedTab: string) {
    if (!this.loadedTabs.get(selectedTab)) {
      this.loadTab(selectedTab)
      this.loadedTabs.set(selectedTab, true)
    }
  }

  private loadTab(tabId: string) {
    switch (tabId) {
      case "info-tab":
        this.personalInfo.refresh(this.participantId, true)
        break
      case "sessions-tab":
        this.participantSessions.refresh(this.participant)
        break
      case "adverse-tab":
        this.adverseEvents.refresh(this.participant)
        break
      case "question-tab":
        this.queryQuestionnaires()
    }
  }

  private queryQuestionnaires() {
    this.screeningForm.refresh(this.participant, "screenings")
    this.screeningForm.disable()
    this.demographicsForm.refresh(this.participant, "demographics")
    this.demographicsForm.disable()
  }
}
