import {Component, Input, SimpleChanges, ViewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../api.service';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-data-collection',
    standalone: true,
    imports: [NgbTooltipModule, FormsModule, NgSelectModule],
    providers: [ApiService],
    templateUrl: './data-collection.component.html',
    styleUrl: './data-collection.component.scss',
})
export class DataCollectionComponent {
    @Input() study
    @Input() editing: boolean = false

    onsiteLists: any[] = [];
    inAppLists: any[] = [];
    videodiarytopicsList: any[] = [];
    topicId!: number;
    durationSecs!: number;
    videodiarytopicsObject: any[] = [{
        topicId: '',
        durationSecs: ''
    }];
    measurementsDropdownlists: any[] = [];
    selectedMeasurement: string = "";
    videoDiaryDisabled = false;

    measurementList: any[] = [];
    surveyList: any[] = [];
    weeks?: number = null;

    @ViewChild("form")
    form: FormGroup

    constructor(
        private api: ApiService,
        private toastr: ToastrService
    ) {
        this.getvideodiarytopics();
        this.api.getmeasurements().subscribe(res => {
            this.measurementsDropdownlists = res?.data;
        });
        // this.fetchMeasurementsList()
        // this.fetchSurveysList()
        this.fetchTasksList()
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        // Add '${implements OnChanges}' to the class.
        this.videoDiaryDisabled = this.inAppLists.filter((e: any) => e.name == 'Video Diary')[0]?.checkbox;
        if (this.study) {
            this.weeks = this.study.durationInWeekPerParticipant
        }
    }

    get weeksArray() {
        if (this.weeks == null) {
            return null
        }
        let array: number[] = []
        for (let i = 1; i <= this.weeks; i++) {
            array.push(i)
        }
        return array
    }

    get measurementDropdownValue() {
        let value = ""
        this.measurementsDropdownlists.forEach(item => {
            if (item.id == this.selectedMeasurement) {
                value = item.name
            }
        })
        return value
    }

    isVideoDiaryTopicDisabled(currentItem) {
        let result = false
        this.videodiarytopicsObject.forEach(item => {
            if (currentItem.id == item.topicId.split('-')[0]) {
                result = true
            }
        })
        return result
    }

    checkVideoDiaryObject() {
        let result = true
        if (!this.videoDiaryDisabled) {
            return true
        }
        if (this.videodiarytopicsObject.length == 0) {
            return false
        }
        let repeatedTopics = false
        let topicIds = []
        this.videodiarytopicsObject.forEach(item => {
            if (item.topicId == "" || item.topicId == null || item.durationSecs == "" || item.durationSecs == null) {
                result = false
            }
            if (topicIds.includes(item.topicId)) {
                repeatedTopics = true
            }
            topicIds.push(item.topicId)
        })
        if (repeatedTopics) {
            result = false
        }
        return result
    }

    fetchTasksList() {
        this.api.listTasks({start:0, size:1000}).subscribe(res => {
            this.measurementList = res.data.filter(item => item.type == "in-app-task" && item.enabled).map(item => {
                return {...item, checkbox: false}
            })
            this.surveyList = res.data.filter(item => item.type == "survey" && item.enabled).map(item => {
                return {...item, checkbox: false}
            })
        })
    }

    private inTaskList(id: number) {
        let selectedTask = null
        this.study?.studyTasks.forEach(task => {
            if (task.task.id == id) {
                selectedTask = task
            }
        })
        return selectedTask
    }

    get inAppTasks() {
        if (this.inAppLists.length == 0) {
            if (this.editing) {
                if (this.study) {
                    this.measurementList.forEach(measurement => {
                        const task = this.inTaskList(measurement.id)
                        if (task != null) {
                            this.inAppLists.push({
                                name: task.task.name,
                                id: task.task.id,
                                checkbox: true,
                                frequency: task.config.frequency,
                                scheduler: task.config.scheduler
                            })
                        } else {
                            this.inAppLists.push(measurement)
                        }
                    })
                }
            } else {
                this.inAppLists = this.measurementList
            }
            this.dairyChange()
        }
        return this.inAppLists
    }

    get inAppSurveys() {
        if (this.onsiteLists.length == 0) {
            if (this.editing) {
                if (this.study) {
                    this.surveyList.forEach(survey => {
                        const task = this.inTaskList(survey.id)
                        if (task != null) {
                            this.onsiteLists.push({
                                name: task.task.name,
                                id: task.task.id,
                                checkbox: true,
                                frequency: task.config.frequency,
                                scheduler: task.config.scheduler
                            })
                        } else {
                            this.onsiteLists.push(survey)
                        }
                    })
                }
            } else {
                this.onsiteLists = this.surveyList
            }
        }
        return this.onsiteLists
    }

    getvideodiarytopics() {
        this.api.getvideodiarytopics({sort: 'id', start: 0, size: 1000}).subscribe(
            (res: any) => {
                this.videodiarytopicsList = res?.data;

            },
            error => {

            }
        );
    }

    add() {
        this.videodiarytopicsObject.push({
            topicId: '',
            durationSecs: ''
        });
    }

    remove(i: any) {
        this.videodiarytopicsObject.splice(i, 1);
    }

    dairyChange() {
        this.videoDiaryDisabled = this.inAppLists.filter((e: any) => e.name == 'Video Diary')[0]?.checkbox;
    }
}
