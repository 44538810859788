<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/study-list">Studies</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add New Study</li>
    </ol>
</nav>

<div class="card">
    <h4 class="card-header">Add New Study</h4>
    <div class="card-body px-2 py-4">
        <mat-stepper [linear]="isLinear" #stepper>
            <mat-step [stepControl]="firstFormGroup" label="Study Information">
                <!-- <form [formGroup]="firstFormGroup"> -->
                <app-study-information></app-study-information>

                <div class="text-end mt-4">
                    <button mat-button class="btn btn-success" (click)="nextStep1()">
                        Next <i class="bi bi-arrow-right ms-1"></i>
                    </button>
                </div>
                <!-- </form> -->
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" label="Available Pre-built Forms">
                <form [formGroup]="secondFormGroup">
                    <app-screening-questionnaire></app-screening-questionnaire>
                    <div class="d-flex justify-content-between mt-5">
                        <button mat-button matStepperPrevious class="btn btn-secondary">
                            <i class="bi bi-arrow-left me-1"></i> Back
                        </button>
                        <button mat-button (click)="screeningNext()" class="btn btn-success">
                            Next <i class="bi bi-arrow-right ms-1"></i>
                        </button>
                    </div>
                </form>

            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" label="Informed Consent Form">
                <form [formGroup]="thirdFormGroup">
                    <app-informed-consent-form></app-informed-consent-form>
                    <div class="d-flex justify-content-between mt-5">
                        <button mat-button matStepperPrevious class="btn btn-secondary">
                            <i class="bi bi-arrow-left me-1"></i> Back
                        </button>
                        <button mat-button (click)="consentForm()" class="btn btn-success">
                            Next <i class="bi bi-arrow-right ms-1"></i>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="fourFormGroup" label="Data Collection">

                <form [formGroup]="fourFormGroup">
                    <app-data-collection [editing]="false"></app-data-collection>
                    <div class="d-flex justify-content-between mt-4">
                        <button mat-button matStepperPrevious class="btn btn-secondary">
                            <i class="bi bi-arrow-left me-1"></i> Back
                        </button>
                        <button mat-button class="btn btn-success" (click)="screeningQuestionnairNext()">
                            Next <i class="bi bi-arrow-right ms-1"></i>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="fiveFormGroup">
                <ng-template matStepLabel>Confirmation</ng-template>


                <section *ngIf="componentLoaded">

                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <h5 class="mb-0">1. STUDY INFORMATION</h5>

                        <a href="#" tooltipClass="custom-tooltip" ngbTooltip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy"> <i class="bi bi-info-circle"></i> </a>
                    </div>

                    <hr class="mt-2 mb-4"/>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <span class="fw-medium">Study Title:</span>
                            {{ StudyInformationComponent?.studyInformationForm?.value?.name }}
                        </div>
                        <div class="col-md-6">
                            <span class="fw-medium">Study Code:</span>
                            {{ StudyInformationComponent?.studyInformationForm?.value?.shortCode }}
                        </div>
                    </div>

                    <h6>Study Description:</h6>
                    <p>
                        {{ StudyInformationComponent?.studyInformationForm?.value?.description }} </p>

                    <div class="row mt-4">
                        <div class="col-md-6">
                            <p>
                                <span class="fw-medium">Start Date:</span>
                                {{ StudyInformationComponent?.studyInformationForm?.value?.startDate | date }}
                            </p>
                            <p>
                                <span class="fw-medium">Planned Number of Participants:</span>
                                {{ StudyInformationComponent?.studyInformationForm?.value?.plannedNumberOfParticipants }}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p>
                                <span class="fw-medium">Planned End Date:</span>
                                {{ StudyInformationComponent?.studyInformationForm?.value?.plannedEndDate | date }}
                            </p>
                            <p>
                                <span class="fw-medium">Study Duration per Participant (Weeks):</span>
                                {{ StudyInformationComponent?.studyInformationForm?.value?.durationInWeekPerParticipant ?? 'No duration defined' }}
                            </p>
                        </div>
                    </div>

                    <div class="row mt-4" *ngIf="StudyInformationComponent?.selectedSites > 0">
                        <div class="col-md-6">
                            <p>
                                <span class="fw-medium">Connected Sites:</span><br>
                                @for (item of StudyInformationComponent?.selectedSites; track item) {
                                    {{ item.name }}<br>
                                }
                            </p>

                        </div>
                    </div>

                    <h5 class="mt-5">2. SCREENING &amp; DEMOGRAPHICS</h5>
                    <hr class="mt-2 mb-3" />
                    <p>
                        <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                        Monitoring Study - Screening Form
                    </p>
                    <p>
                        <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check" />
                        Monitoring Study - Demographics Form
                    </p>

                    <h5 class="mt-5">3. INFORMED CONSENT FORMS</h5>
                    <hr class="mt-2"/>

                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-2">Informed Consent Form for Participant (Adult)</h5>
                            <hr class="mt-2 mb-3"/>
                        </div>
                    </div>
                    <div [innerHTML]="InformedConsentFormComponent?.formGroup.value.icfParticipant"></div>
                    @if (InformedConsentFormComponent?.participantList.length > 0) {
                        <p class="fw-medium mb-2 mt-4">Additional Clauses</p>
                        <ul class="pl-4">
                            @for (item of InformedConsentFormComponent?.participantList; track $index) {
                                <li [innerHTML]="item"></li>
                            }
                        </ul>
                    }

                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-5">Informed Consent Form for Participant (Children)</h5>
                            <hr class="mt-2 mb-3"/>
                        </div>
                    </div>
                    <div [innerHTML]="InformedConsentFormComponent?.formGroup.value.icfParticipantAdolescent"></div>
                    @if (InformedConsentFormComponent?.participantAdolescentList.length > 0) {
                        <p class="fw-medium mb-2 mt-4">Additional Clauses</p>
                        <ul class="pl-4">
                            @for (item of InformedConsentFormComponent?.participantAdolescentList; track $index) {
                                <li [innerHTML]="item"></li>
                            }
                        </ul>
                    }

                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-5">Informed Consent Form for Parent/Carer</h5>
                            <hr class="mt-2 mb-3"/>
                        </div>
                    </div>
                    <div [innerHTML]="InformedConsentFormComponent?.formGroup.value.icfCarer"></div>
                    @if (InformedConsentFormComponent?.carerList.length > 0) {
                        <p class="fw-medium mb-2 mt-4">Additional Clauses</p>
                        <ul class="pl-4">
                            @for (item of InformedConsentFormComponent?.carerList; track $index) {
                                <li [innerHTML]="item"></li>
                            }
                        </ul>
                    }

                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-5">Control Check for Study Researcher</h5>
                            <hr class="mt-2 mb-3"/>
                        </div>
                    </div>
                    <div [innerHTML]="InformedConsentFormComponent?.formGroup.value.icfStudyManager"></div>
                    @if (InformedConsentFormComponent?.studyManagerList.length > 0) {
                        <p class="fw-medium mb-2 mt-4">Additional Clauses</p>
                        <ul class="pl-4">
                            @for (item of InformedConsentFormComponent?.studyManagerList; track $index) {
                                <li [innerHTML]="item"></li>
                            }
                        </ul>
                    }

                    <h5 class="mt-5">4. DATA COLLECTION</h5>
                    <hr class="mt-2 mb-3"/>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <p class="text-success fw-medium mb-1 mt-4 mt-md-0">On-site Measurements</p>
                            <ul>
                                <li>
                                    <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check"/>
                                    {{ DataCollectionComponent?.measurementDropdownValue }}
                                </li>
                            </ul>

                        </div>
                        <div class="col-md-4">
                            <p class="text-success fw-medium mb-1 mt-4 mt-md-0">In-app Digital Measurements </p>
                            <ul>
                                @for (item of inAppLists; track $index) {
                                    <li>
                                        <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check"/>{{ item?.name }}
                                    </li>
                                }
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <p class="text-success fw-medium mb-1">In-app Scales/Surveys</p>
                            <ul>
                                @for (item of onsiteLists; track $index) {
                                    <li>
                                        <img class="me-1" width="20" src="assets/images/check-mark.png" alt="check"/>{{ item?.name }}
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>

                    @if (DataCollectionComponent?.videoDiaryDisabled) {
                        <p class="text-success fw-medium mb-1 mt-4 pt-2">Video Diary Topics Battery </p>
                        @for (item of DataCollectionComponent?.videodiarytopicsObject; track $index) {
                            <div class="row">
                                <div class="col">
                                    {{ item?.topicId }}
                                </div>
                                <div class="col-2">
                                    Duration: {{ item?.durationSecs }} seconds
                                </div>
                            </div>
                        }
                    }

                </section>

                <div class="d-flex justify-content-between mt-5">
                    <button mat-button matStepperPrevious class="btn btn-secondary">
                        <i class="bi bi-arrow-left me-1"></i> Back
                    </button>
                    <button mat-button class="btn btn-success" (click)="onSubmit()">Submit</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
