import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ApiService } from '../../api.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { OnInit } from '@angular/core';
import { Roles } from '@models/roles.enum';
import { PermissionPipe } from '@components/directives/permission.pipe';
import { Permissions } from '@models/permissions.enum';
import { SpinnerComponent } from '@components/spinner/spinner.component';

@Component({
  selector: 'app-site-list',
  standalone: true,
  imports: [
    CommonModule, NgxDatatableModule,
    RouterLink, PermissionPipe, SpinnerComponent],
  providers: [ApiService],
  templateUrl: './site-list.component.html',
  styleUrl: './site-list.component.scss',
})
export class SiteListComponent implements OnInit, AfterViewInit {

  rows = [];


  page = {
    totalElements: 0,
    pageNumber: 0,
    size: 10
  };

  loading = false;
  Permissions = Permissions;

  @ViewChild('datatable') datatable: ElementRef;

  constructor(private api: ApiService) { }

  ngAfterViewInit(): void {
    this.setPage({ offset: 0 });
  }
  ngOnInit(): void { }

  fetchSiteList() {
    this.loading = true;
    this.api.siteListing({ sort: 'id', start: this.page.pageNumber, size: this.page.size }).subscribe(
      (res: any) => {
        this.loading = false;
        if (!res?.data?.length) {
          return;
        }
        this.page.totalElements = res.pagination.totalCount;
        this.rows = [...res.data];
      },
      error => {
        console.error('Fetch site list error:', error);
        this.loading = false;
      }
    );
  }

  setPage(e: any) {
    this.page.pageNumber = e.offset;
    this.fetchSiteList();
  }
}
