import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ApiService } from '../../api.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { SpinnerComponent } from '@components/spinner/spinner.component';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, ReactiveFormsModule, HttpClientModule, SpinnerComponent],
  providers: [ApiService,],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss',
})
export class SigninComponent {
  signinForm!: FormGroup;
  errorMessage!: string;
  loading = false;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.authService.isUserLogged) {
      this.router.navigate(['/study-list']);
    }
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  onSubmit() {
    if (this.signinForm.valid) {
      this.loading = true
      const { email, password } = this.signinForm.value;
      this.authService.signIn(email, password).subscribe(
        (res: any) => {
          this.loading = false
          // Handle successful sign-in (redirect, show message, etc.)
          if (res && res.data && res.data.token) {
            localStorage.setItem('token', res.data.token);
            this.router.navigate(['/study-list']);
            this.toastr.success('Sign In Successful', ' ');
          } else {
            this.toastr.error(res.message, ' ');
          }
        },
        error => {
          this.loading = false
          this.errorMessage = "Invalid Credentials";
          this.toastr.error(this.errorMessage); // Assuming API returns error message
          console.error('Sign in error:', error);
        }
      );
    } else {
      // Mark fields as touched to trigger validation messages
      this.signinForm.markAllAsTouched();
    }
  }
}
