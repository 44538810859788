import {Component, Input, ViewChild} from '@angular/core';
import {ApiService} from "../../api.service";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {SpinnerComponent} from "@components/spinner/spinner.component";
import {NgClass, NgIf} from "@angular/common";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'change-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SpinnerComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  loading: boolean = false

  formGroup = this.formBuilder.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', (control: FormControl) => {
      if (
          !control.value.match(this.regexUppercase) ||
          !control.value.match(this.regexLowercase) ||
          !control.value.match(this.regexDigit) ||
          !control.value.match(this.regexSpecial) ||
          !control.value.match(this.regexLength)
      ) {
        return { required: true }
      }
      return null
    }],
    repeatPassword: ['', Validators.required],
  }, {
    validators: [
      (control) => {
        if (control.get("newPassword").value != control.get("repeatPassword").value) {
          control.get("repeatPassword").setErrors({ ...(control.errors || {}), match: "Value does not match" })
        }
        return null
      }
    ]
  })

  regexUppercase: string = "^(?=.*[A-Z])";
  regexLowercase: string = "(?=.*[a-z])";
  regexDigit: string = "(.*[0-9].*)";
  regexSpecial: string = "(?=.*[^A-Za-z0-9])";
  regexLength: string = ".{8,}";

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
  }

  changedPassword() {
    this.formGroup.get("repeatPassword").updateValueAndValidity()
  }

  submitForm() {
    if (this.formGroup.valid) {
      this.loading = true
      const values = this.formGroup.value
      this.apiService.changePassword(values.currentPassword, values.newPassword, values.repeatPassword).subscribe(response => {
        this.loading = false
        this.toastr.success(response.message)
      }, error => {
        this.loading = false
        this.toastr.error(error.error.message)
      })

    } else {
      this.toastr.error('Please provide a value for all the required fields')
      this.formGroup.markAllAsTouched()
    }

  }
}
