<ngx-datatable class="bootstrap" #table
        [rows]="rows"
        [columnMode]="'force'"
        [headerHeight]="50"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="30"
        [rowHeight]="'auto'"
        [limit]="page.size"
        [count]="page.totalCount"
        [offset]="page.start"
        (page)="setPage($event)"
        [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
        (sort)="sort($event)"
>
    <ngx-datatable-column prop="scheduledAt" name="Date" [sortable]="true" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.scheduledAt | date }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="user" name="Study Nurse" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.studyMember.user.firstName }} {{ row?.studyMember.user.lastName }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="status" name="Status" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge text-bg-secondary" *ngIf="row?.status == 'SCHEDULED'">Scheduled</span>
            <span class="badge text-bg-danger" *ngIf="row?.status == 'OVERDUE'">Overdue</span>
            <span class="badge text-bg-info" *ngIf="row?.status == 'IN-PROGRESS'">In-progress</span>
            <span class="badge text-bg-success" *ngIf="row?.status == 'COMPLETED'">Completed</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column  name="Actions" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/sessions', row.id]" class="btn btn-sm btn-outline-success px-3">
                View details
            </a>
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable>
