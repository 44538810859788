import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ApiService} from "../../../../api.service";
import {SpinnerComponent} from "../../../spinner/spinner.component";
import {Router} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
    selector: 'complete',
    standalone: true,
    imports: [
        SpinnerComponent,
        NgIf
    ],
    templateUrl: './complete.component.html',
})
export class CompleteComponent {
    @Input() participantId: number
    @ViewChild('closeModal') closeModal: ElementRef

    loading: boolean = false

    constructor(
        private apiService: ApiService,
        private router: Router
    ) {
    }

    confirm() {
        this.apiService.completeParticipant(this.participantId).subscribe(response => {
            this.loading = false
            this.closeModal.nativeElement.click()
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl('/participant-overview/' + this.participantId);
            });
        })
    }
}
