<div class="mb-4">
    Participant Code Assigned: <b>{{ participant?.participantCode }}</b>
</div>

<h5 class="mb-0 mt-4 pt-4">Study Information</h5>
<hr class="mt-2 mb-4"/>

<div class="row mb-4">
    <div class="col-md-6">
        <p><span class="fw-medium">Study:</span> {{ participant?.studySite.study.name }} ({{ participant?.studySite.study.code }})</p>
    </div>
    <div class="col-md-6">
        <p><span class="fw-medium">Study Site:</span> {{ participant?.studySite.site.name }}</p>
    </div>
</div>

<h5 class="mb-0 pt-4 mt-4">Screening form</h5>
<hr class="mt-2 mb-4"/>

<div class="mb-4 pb-4">
    <questionnaire-form #screeningForm [disabled]="true"></questionnaire-form>
</div>

<h5 class="mb-0 pt-4 mt-4">Personal Information</h5>
<hr class="mt-2 mb-4"/>

<div class="row">
    <div class="col-md-6">
        <p><span class="fw-medium">Last Name:</span> {{ participant?.patient.user.lastName }}</p>
        <p><span class="fw-medium">Email:</span> {{ participant?.patient.user.email }}</p>
        <p><span class="fw-medium">NHS Number:</span> {{ participant?.patient.nhsNumber }}</p>
        <p><span class="fw-medium">Initial weight (Kg):</span> {{ participant?.patient.weight }}</p>
        <p *ngIf="participant?.type != 'Healthy Subject'"><span class="fw-medium">What is their diagnosed type of Eating Disorders?:</span> {{ participant?.patientEdDiagnoses[0].name }}</p>
        <p><span class="fw-medium">Are they taking medication (excluding supplements)?:</span> {{ participant?.isTakingMedication? 'Yes' : 'No' }}</p>
        <p *ngIf="participant?.isTakingMedication"><span class="fw-medium">Please specify all medications (generic names and dose):</span> {{ participant?.medicationNotes }}</p>
    </div>
    <div class="col-md-6">
        <p><span class="fw-medium">Date of Birth:</span> {{ participant?.patient.dateOfBirth | date:'d MMMM y' }}</p>
        <p><span class="fw-medium">Biological Sex:</span> {{ participant?.patient.biologicalSex }}</p>
        <p><span class="fw-medium">&nbsp;</span></p>
        <p><span class="fw-medium">Initial height (cm):</span> {{ participant?.patient.height }}</p>
        <p *ngIf="participant?.type != 'Healthy Subject'"><span class="fw-medium">When were they first diagnosed with an eating disorder?:</span> {{ participant?.patientEdDiagnoses[0].firstDiagnosedAt | date:'d MMMM y' }}</p>
    </div>
</div>
<div class="row mb-2">
    <div class="col-md-6">
        <div class="row mb-3">
            <div class="col-auto"><span class="fw-medium">Co-morbidities:</span></div>
            <div class="col">
                @for (comorbidity of participant?.patientComorbidities; track comorbidity) {
                    {{ comorbidity.name }}<br>
                }
            </div>
        </div>
        <p *ngIf="participant?.type != 'Healthy Subject'"><span class="fw-medium">What best describes their current food intake status?:</span> {{ participant?.foodIntakeStatus.status }}</p>
    </div>
    <div class="col-md-6">
        <p><span class="fw-medium">Type:</span> {{ participant?.type }}</p>
    </div>
</div>
<!--<div class="row mb-4">-->
<!--    <div class="col-md-12">-->
<!--        @if (participant?.lackOfCapability) {-->
<!--            <i class="bi bi-check-square"></i>-->
<!--        }-->
<!--        @else {-->
<!--            <i class="bi bi-square"></i>-->
<!--        }-->
<!--        Participant lacks the capability to consent to participate in this study as adult-->
<!--    </div>-->
<!--</div>-->

@if (participant?.carer?.id != null) {
    <h5 class="mb-0 pt-4">Personal Information (Parent/Carer)</h5>
    <hr class="mt-2 mb-4"/>

    <div class="row pb-4">
        <div class="col-md-6">
            <p><span class="fw-medium">First Name:</span> {{ participant?.carer.user.firstName }}</p>
            <p><span class="fw-medium">Email:</span> {{ participant?.carer.user.email }}</p>
            <p><span class="fw-medium">Relationship to participant:</span> {{ participant?.carer.relationship.name }}</p>
        </div>
        <div class="col-md-6">
            <p><span class="fw-medium">Last Name:</span> {{ participant?.carer.user.lastName }}</p>
            <p><span class="fw-medium">Date of Birth:</span> {{ participant?.carer.dateOfBirth | date:'d MMMM y' }}</p>
        </div>
    </div>
}

<h5 class="mb-0 pt-4 mt-4">Demographics form</h5>
<hr class="mt-2 mb-4"/>

<div class="mb-4">
    <questionnaire-form #demographicsForm [disabled]="true"></questionnaire-form>
</div>

<div class="mt-4 d-flex justify-content-between">
    <button type="button" class="btn btn-outline-info" (click)="prev()">
        <i class="bi bi-arrow-left ms-1"></i> Prev
    </button>
    <button class="btn btn-success" (click)="processForm()">
        Submit and send ICFs
    </button>
</div>

<spinner *ngIf="loading"></spinner>
