<spinner *ngIf="loading"></spinner>

<ngx-datatable class="bootstrap" #table
        [rows]="rows"
        [columnMode]="'force'"
        [headerHeight]="50"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="30"
        [rowHeight]="'auto'"
        [limit]="page.size"
        [count]="page.totalCount"
        [offset]="page.start"
        (page)="setPage($event)"
        [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
        (sort)="sort($event)"
>
    <ngx-datatable-column prop="startDate" name="Start date" [sortable]="true" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.startDate | date }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="endDate" name="End date" [sortable]="true" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.endDate | date }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="severity" name="Severity" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.adverseEventSeverity.name }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="description" name="Description" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span ngbTooltip="{{row?.description}}">
                {{ row?.description?.length > 25? (row?.description | slice:0:25) + '...' : row?.description }}
            </span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column  name="Actions" [sortable]="false" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            @if (disabled) {
                <button class="btn btn-sm btn-outline-secondary" (click)="viewEdit(true, true, row)">
                    View details
                </button>
            } @else {
                <button
                  [permission]="Permissions.EDIT_ADVERSE_EVENT"
                  class="btn btn-sm btn-outline-info" (click)="viewEdit(true, false, row)">
                    Edit
                </button>
                &nbsp;
                <delete-adverse-event
                  [permission]="Permissions.DELETE_ADVERSE_EVENT"
                  (result)="refreshRows()" [participantId]="participant.id" [adverseEventId]="row.id"></delete-adverse-event>
            }
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable>

<div class="d-flex justify-content-end mt-4" *ngIf="!disabled">
    <button
      [permission]="Permissions.ADD_ADVERSE_EVENT"
      class="btn btn-success" (click)="viewEdit(false, false, null)">
        <i class="bi bi-plus"></i>
        New adverse event
    </button>
</div>

<edit-adverse-event (result)="refreshRows()"></edit-adverse-event>
