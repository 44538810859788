<div class="d-flex justify-content-between align-items-center mt-2">
  <h5 class="mb-0">Study Site Confirmation</h5>
  <a
  
    tooltipClass="custom-tooltip"
    ngbTooltip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy"
  >
    <i class="bi bi-info-circle"></i>
  </a>
</div>

<hr class="mt-2 mb-4" />

<div class="row mb-3">
  <div class="col-md-6">
    <p><span class="fw-medium">Name:</span> Demo study name</p>
    <p><span class="fw-medium">Cit:</span> London</p>
    <p><span class="fw-medium">Country:</span> United Kingdom</p>
  </div>
  <div class="col-md-6">
    <p><span class="fw-medium">Address:</span> Demo address here</p>
    <p><span class="fw-medium">Postal code:</span> ABD123-1234</p>
  </div>
</div>

<h5 class="mt-5">Studies connected</h5>
<hr class="mt-2 mb-3" />

<p><span class="fw-medium">Study:</span> Demo study name 1</p>
<p><span class="fw-medium">Study:</span> Demo study name 2</p>

<h5 class="mt-5">Clinical devices</h5>
<hr class="mt-2 mb-3" />

<p><span class="fw-medium">Devices #1:</span> Site's demo device #1</p>
<p><span class="fw-medium">Devices #2:</span> Site's demo device #2</p>
