
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Profile</li>
    </ol>
</nav>

<div class="card">
    <h4 class="card-header d-flex justify-content-between">
        <span>{{ user?.firstName }} {{ user?.lastName }}</span>
    </h4>
    <div class="card-body">
        <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="'study-tab'">
                <button ngbNavLink>
                    <i class="bi bi-info-square"></i> Personal Information
                </button>
                <ng-template ngbNavContent>
                    <personal-info [user]="user"></personal-info>
                </ng-template>
            </li>
            <li [ngbNavItem]="'info-tab'">
                <button ngbNavLink>
                    <i class="bi bi-key"></i> Change Password
                </button>
                <ng-template ngbNavContent>
                    <change-password></change-password>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
