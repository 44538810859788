import {HttpClientModule} from "@angular/common/http";
import {Component} from "@angular/core";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ApiService} from "../../../api.service";
import {CommonModule} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {Site} from "../../../api/api.interfaces";

@Component({
    selector: "app-study-information",
    standalone: true,
    imports: [NgbTooltipModule, ReactiveFormsModule, HttpClientModule, CommonModule, NgSelectModule],
    providers: [ApiService,],
    templateUrl: "./study-information.component.html",
    styleUrl: "./study-information.component.scss",
})
export class StudyInformationComponent {
    studyInformationForm!: FormGroup;
    errorMessage = false;
    sites: Site[] = []

    constructor(private fb: FormBuilder, private authService: ApiService) {
    }

    ngOnInit(): void {
        this.studyInformationForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            shortCode: ['', Validators.required],
            startDate: ['', Validators.required],
            plannedEndDate: ['', Validators.required],
            plannedNumberOfParticipants: ['', Validators.required],
            readOnlyduration: [false],
            sites: [[]],
            durationInWeekPerParticipant: [''],
        }, {validators: [
                (control) => {
                    if ((control.get("durationInWeekPerParticipant").value == "" || control.get("durationInWeekPerParticipant").value == null) && !control.get("readOnlyduration").value) {
                        control.get("durationInWeekPerParticipant").setErrors({...(control.errors || {}), message: "This value is before the start date"})
                    }
                    if (control.get("startDate").value == "" || control.get("plannedEndDate").value == "") {
                        return null
                    }
                    if (control.get("startDate").value > control.get("plannedEndDate").value) {
                        control.get("plannedEndDate").setErrors({...(control.errors || {}), message: "This value is before the start date"})
                    }
                    return null
                }
            ]
        });

        this.authService.siteListing({size:99999}).subscribe(res => {
            this.sites = res?.data;
        });
    }

    get selectedSites() {
        return this.sites.filter(site => this.studyInformationForm.get("sites").value?.includes(site.id))
    }

    startDateChanged() {
        this.studyInformationForm.get("plannedEndDate").setValue(this.studyInformationForm.get("plannedEndDate").value)
    }

    changedNoDefindedDuration() {
        if (this.studyInformationForm.get("readOnlyduration").value) {
            this.studyInformationForm.get("durationInWeekPerParticipant").setValue(null)
            this.studyInformationForm.get("durationInWeekPerParticipant").disable()
        } else {
            this.studyInformationForm.get("durationInWeekPerParticipant").enable()
        }
    }

    changedDuration() {
        const checked = this.studyInformationForm.get("durationInWeekPerParticipant").value == null;
        this.studyInformationForm.get("readOnlyduration").setValue(checked)
    }
}
