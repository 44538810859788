import { Component } from '@angular/core';

@Component({
  selector: 'app-site-overview-info',
  standalone: true,
  imports: [],
  templateUrl: './site-overview-info.component.html',
  styleUrl: './site-overview-info.component.scss'
})
export class SiteOverviewInfoComponent {

}
