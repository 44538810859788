import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { ApiService } from '../../api.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { SpinnerComponent } from '@components/spinner/spinner.component';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, ReactiveFormsModule, HttpClientModule, SpinnerComponent],
  providers: [ApiService,],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  passForm!: FormGroup;
  errorMessage!: string;
  emailSent = false;
  loading = false;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeParams();
  }

  initForm() {
    this.passForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  subscribeParams() {
    this.route.queryParams.subscribe((params: { email: string }) => {
      if (params?.email) {
        setTimeout(() => {
          this.passForm.patchValue({ email: params.email });
        }, 500);
      }
    });
  }

  onSubmit() {
    if (this.passForm.valid && !this.loading) {
      this.loading = true;
      const { email } = this.passForm.value;
      this.authService.forgotPassword(email).subscribe(
        (res: any) => {
          this.emailSent = true;
          this.toastr.success(res?.message);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.errorMessage = error.error.message;
          this.toastr.error(error.error.message, ' '); // Assuming API returns error message
          console.error('Sign in error:', error);
          this.loading = false;
        }
      );
    } else {
      // Mark fields as touched to trigger validation messages
      this.passForm.markAllAsTouched();
    }
  }

}
