import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ApiService } from '../../api.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { Roles } from '@services/models/roles.enum';
import { LogginedUser } from '@services/models/user.model';


export const userMetaData = {
  [Roles.Admin]: {
    role: 'Admin',
    icon: 'assets/images/avatar-admin.png'
  },
  [Roles.StudyManager]: {
    role: 'Study nurse',
    icon: 'assets/images/avatar.png'
  }
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterOutlet, RouterLink, HttpClientModule],
  providers: [ApiService,],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {

  user: LogginedUser;

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.getLoginedUser();
    this.authService.loggedUser$.subscribe((user: LogginedUser) => {
      this.user = user
    })
  }

  private getLoginedUser() {
    this.authService.getLogginedUser()
        .subscribe((user: LogginedUser) => {
          this.user = user;
        });
  }

  get avatar() {
    return this.user ? userMetaData[this.user.roleName] : null;
  }

  logOut() {

    this.authService.signOut().subscribe(
        (res: any) => {
          // Handle successful sign-in (redirect, show message, etc.)
          if (res) {
            this.toastr.success(res.message, 'Sign out Successfully');
          }
        },
        error => {
          this.toastr.error(error.error.message, ' ');
          this.router.navigate(['/signin']);
          // this.toastr.error(error.error.message,' '); // Assuming API returns error message
          console.error('Sign in error:', error);
        }
    );
  }
}
