<h5 class="mb-0">Site Information</h5>
<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Site Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter Site name here"
      />
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter Address here"
      />
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">City</label>
      <input type="text" class="form-control" placeholder="Enter City here" />
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Postal Code</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter Postal Code here"
      />
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Country</label>
      <select class="form-select" aria-label="Default select example">
        <option selected>Select one Country</option>
        <option value="1">Spain</option>
        <option value="2">United State</option>
      </select>
    </div>
  </div>
</div>

<h5 class="mt-4 mb-0">Connect site to study</h5>
<hr class="mt-2 mb-4" />

<div class="row">
  <div class="col-md-6">
    <div class="form-group mb-3">
      <label class="form-label">Select studies</label>
      <select class="form-select" aria-label="Default select example">
        <option selected>Select one or more options</option>
        <option value="1">Spain</option>
        <option value="2">United State</option>
      </select>
    </div>
  </div>
</div>
