<header>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center w-100">
        <a
          [routerLink]="['/']"
          ><img
            class="d-lg-none"
            width="80"
            src="assets/images/logo.svg"
            alt="logo"
        /></a>
        <!-- <form class="d-none d-lg-flex" role="search">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search here"
            aria-label="Search"
          />
          <button class="btn" type="submit">
            <i class="bi bi-search"></i>
          </button>
        </form> -->
        <ul class="navbar-nav ms-auto mb-0">
          <li class="nav-item dropdown px-0">
            <a
              class="nav-link p-0"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
            @if(avatar) {
              <div class="d-flex align-items-center">
                <img [src]="avatar.icon" alt="logo" />
                <div class="ms-2 ps-1">
                  <h6 class="mb-0">{{user.fullName || user.role}}</h6>
                  <small class="mb-0" [hidden]="!user.fullName">{{avatar.role}}</small>
                </div>
              </div>
            }
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" [routerLink]="['/profile']"><i class="bi bi-person"></i> My profile</a>
              </li>
                <!-- <li>
                  <a class="dropdown-item" href="#"
                    ><i class="bi bi-gear"></i> Settings</a
                  >
                </li> -->
              <li>
                <a class="dropdown-item" (click)="logOut()"><i class="bi bi-box-arrow-right"></i> Sign Out</a>
              </li>
            </ul>
          </li>
        </ul>
        <a
          class="d-lg-none text-success ms-2"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling"
          aria-controls="offcanvasScrolling"
        >
          <i class="bi bi-list"></i>
        </a>
      </div>
    </div>
  </nav>
</header>
