import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatStepperNext } from "@angular/material/stepper";
import { ApiService } from "../../../../api.service";
import { PostParticipantFormResultsResponse } from "../../../../api/api.interfaces";
import { ToastrService } from "ngx-toastr";
import { NgIf } from "@angular/common";
import { FormFieldComponent } from "../form-field/form-field.component";
import { QuestionnaireFormComponent } from "../form/questionnaire-form.component";
import { ParticipantsService } from '@services/participants.service';
import { Participant } from '@models/participant.model';
import {WithdrawnComponent} from "@components/participants/overview/withdrawn/withdrawn.component";

@Component({
  selector: 'demographics',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButton,
    MatStepperNext,
    NgIf,
    FormFieldComponent,
    QuestionnaireFormComponent,
    WithdrawnComponent
  ],
  templateUrl: './demographics.component.html',
})
export class DemographicsComponent {

  @Output() formResponse = new EventEmitter<PostParticipantFormResultsResponse>();
  @Output() prevClicked = new EventEmitter<{}>

  @ViewChild(QuestionnaireFormComponent) questionaireForm: QuestionnaireFormComponent;

  participant: Participant;

  constructor(
    private apiService: ApiService,
    private participantsService: ParticipantsService,
    private toastr: ToastrService
  ) {
  }

  refresh(participantId: number) {
    this.participantsService.getById(participantId).subscribe(response => {
      this.participant = response.data
      this.questionaireForm.refresh(this.participant, "demographics")
    })
  }

  prev() {
    this.prevClicked.emit({})
  }

  submitForm() {
    if (this.questionaireForm.isValid()) {
      this.apiService.postParticipantFormResults(this.participant.id, this.questionaireForm.getFormId(), this.questionaireForm.getResult()).subscribe(response => {
        this.formResponse.emit(response)
      })
    } else {
      this.toastr.error("Please provide a value for all the required fields");
    }
  }
}
