import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { PersonalInfoComponent } from '../../components/participants/add-new/personal-info/personal-info.component';
import { ConfirmationComponent } from '../../components/participants/add-new/confirmation/confirmation.component';
import { StudyInformationComponent } from "../../components/participants/add-new/study-information/study-information.component";
import { ScreeningFormComponent } from "../../components/participants/add-new/screening-form/screening-form.component";
import { ApiService } from "../../api.service";
import { Site, Study } from "../../api/api.interfaces";
import { ActivatedRoute, Router } from "@angular/router";
import { DemographicsComponent } from "../../components/participants/add-new/demographics/demographics.component";
import { NgIf } from "@angular/common";
import { SpinnerComponent } from "../../components/spinner/spinner.component";
import { Participant, ParticipantStatus } from '@models/participant.model';
import { ParticipantsService } from '@services/participants.service';

@Component({
  selector: 'app-add-new-participant',
  standalone: true,
  imports: [
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PersonalInfoComponent,
    ConfirmationComponent,
    StudyInformationComponent,
    ScreeningFormComponent,
    DemographicsComponent,
    NgIf,
    SpinnerComponent,
  ],
  templateUrl: './add-new-participant.component.html',
  styleUrl: './add-new-participant.component.scss',
})
export class AddNewParticipantComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(StudyInformationComponent) studyInformation: StudyInformationComponent;
  @ViewChild(ScreeningFormComponent) screeningForm: ScreeningFormComponent;
  @ViewChild(PersonalInfoComponent) personalInfo: PersonalInfoComponent;
  @ViewChild(DemographicsComponent) demographics: DemographicsComponent;
  @ViewChild(ConfirmationComponent) confirmation: ConfirmationComponent;

  loading: boolean = true

  participant: Participant;
  participantId: number;
  studyId: number;
  siteId: number;

  study: Study;
  site: Site;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private participantsService: ParticipantsService,
    private activeRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.participantId = this.activeRoute.snapshot.params['id']
    if (this.participantId == undefined) {
      this.loading = false
    } else {
      this.participantsService.getById(this.participantId).subscribe(response => {
        this.loading = false
        this.participant = response.data
        this.studyId = this.participant.studySite.study.id
        if (this.participant.status.status == ParticipantStatus.Draft
          || this.participant.status.status == ParticipantStatus.ScreenedOut) {
          this.screeningForm.refresh(this.participantId)
          this.stepper.steps.get(0).completed = true
          this.stepper.selectedIndex = 1
        }
        if (this.participant.status.status == ParticipantStatus.IcfPending
          || this.participant.status.status == ParticipantStatus.IcfSigned) {
          if (this.participant.wizardStep <= 4) {
            this.personalInfo.refresh(this.participantId, false)
            this.stepper.steps.get(0).completed = true
            this.stepper.steps.get(1).completed = true
            this.stepper.selectedIndex = 2
          }
          if (this.participant.wizardStep == 5) {
            this.demographics.refresh(this.participantId)
            this.stepper.steps.get(0).completed = true
            this.stepper.steps.get(1).completed = true
            this.stepper.steps.get(2).completed = true
            this.stepper.selectedIndex = 3
          }
          if (this.participant.wizardStep == 6) {
            this.confirmation.refresh(this.participantId)
            this.stepper.steps.get(0).completed = true
            this.stepper.steps.get(1).completed = true
            this.stepper.steps.get(2).completed = true
            this.stepper.steps.get(3).completed = true
            this.stepper.selectedIndex = 4
          }
        }
        // TODO Unlock screen
      })
    }
  }

  finishStudyParticipant(data: {
    participantId: number;
    studyId: number;
    siteId: number
  }) {
    this.participantId = data.participantId
    this.studyId = data.studyId
    this.siteId = data.siteId

    this.screeningForm.refresh(this.participantId)

    this.router.navigateByUrl('/add-new-participant/' + this.participantId)
    this.stepper.steps.get(0).completed = true
    this.stepper.next()
  }

  prev() {
    const currentIndex = this.stepper.selectedIndex
    this.stepper.steps.get(currentIndex - 1).editable = true
    if (currentIndex == 3) {
      this.personalInfo.refresh(this.participantId, false)
    }
    if (currentIndex == 4) {
      this.demographics.refresh(this.participantId)
    }
    this.stepper.previous()
  }

  finishScreeningForm() {
    this.personalInfo.refresh(this.participantId, false)
    this.stepper.steps.get(1).completed = true
    this.stepper.next()
  }

  finishPersonalInfo() {
    this.demographics.refresh(this.participantId)
    this.stepper.steps.get(2).completed = true
    this.stepper.next()
  }

  finishDemographicForm() {
    this.confirmation.refresh(this.participantId)
    this.stepper.steps.get(3).completed = true
    this.stepper.next()
  }
}
